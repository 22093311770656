import React, { useEffect, useState } from 'react';
import firebase from 'firebase';
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom';

// Componentes
import Sidebar from '../../Componentes/Sidebar'
import Header from '../../Componentes/Header';
import moment from 'moment';

export default function Posts() {
    const [servicos] = useState({
        auth: firebase.auth(),
        projetos: firebase.firestore().collection('projetos'),
    })
    const [projetos, setProjetos] = useState([])
    const [filtro, setFiltro] = useState('')

    useEffect(() => {
        servicos.projetos.get().then((snap) => {
            const data = snap.docs.map((d) => d.data())
            setProjetos(data)
        })
    }, [])

    function filtrar() {
        if (!filtro) {
            mensagemAlerta('Escolha o filtro')
            return
        }
        mensagemLoading('Carregando Filtro')
        servicos.projetos.where('status', '==', filtro).get().then((snap) => {
            const data = snap.docs.map((d) => d.data())
            setProjetos(data)
            Swal.close()
        })

    }

    // Inputs
    const handleFiltro = (event) => {
        event.persist();
        setFiltro(event.target.value);
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <>
            <div className="dashboard-main-wrapper">
                <Header />
                <Sidebar />
                <div className="main-panel">
                    <div className="content">
                        <div className="page-inner">
                            <div className="page-header">
                                <h4 className="page-title">Projetos</h4>
                                <div className="btn-group btn-group-page-header ml-auto">
                                    <button type="button" className="btn btn-light btn-round btn-page-header-dropdown dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fa fa-ellipsis-h" />
                                    </button>
                                    <div className="dropdown-menu">
                                        <div className="arrow" />
                                        <a className="dropdown-item" href="/cadastroProjeto">Novo Projeto</a>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 col-md-4">
                                    <div className="card card-stats card-round">
                                        <div className="card-body ">
                                            <div className="row align-items-center">
                                                <div className="col-icon">
                                                    <div className="icon-big text-center icon-success bubble-shadow-small">
                                                        <i className="fas fa-check" />
                                                    </div>
                                                </div>
                                                <div className="col col-stats ml-3 ml-sm-0">
                                                    <div className="numbers">
                                                        <p className="card-category">Ativos</p>
                                                        <h4 className="card-title">{projetos.filter((d) => d.status === 'ATIVO').length}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4">
                                    <div className="card card-stats card-round">
                                        <div className="card-body">
                                            <div className="row align-items-center">
                                                <div className="col-icon">
                                                    <div className="icon-big text-center icon-warning bubble-shadow-small">
                                                        <i className="fas fa-exclamation-circle" />
                                                    </div>
                                                </div>
                                                <div className="col col-stats ml-3 ml-sm-0">
                                                    <div className="numbers">
                                                        <p className="card-category">Em Análise</p>
                                                        <h4 className="card-title">{projetos.filter((d) => d.status === 'RASCUNHO').length}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4">
                                    <div className="card card-stats card-round">
                                        <div className="card-body">
                                            <div className="row align-items-center">
                                                <div className="col-icon">
                                                    <div className="icon-big text-center icon-danger bubble-shadow-small">
                                                        <i className="far fa-window-close" />
                                                    </div>
                                                </div>
                                                <div className="col col-stats ml-3 ml-sm-0">
                                                    <div className="numbers">
                                                        <p className="card-category">Inativos</p>
                                                        <h4 className="card-title">{projetos.filter((d) => d.status === 'INATIVO').length}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="card-header">
                                                <div class="card-title">Lista de Projetos do Fábrica</div>
                                            </div>
                                            <div className="card-sub">
                                                Aqui estão listadas todos os projetos que o Fábrica executa ou realizará futuramente
                                            </div>
                                            <table className="table mt-3">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Avatar</th>
                                                        <th scope="col">Projeto</th>
                                                        <th scope="col">Descrição</th>
                                                        <th scope="col">Tipo</th>
                                                        <th scope="col">Detalhe</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {projetos.map((projeto) =>
                                                        <tr>
                                                            <td>
                                                                <div className="avatar avatar-sm">
                                                                    <img src={projeto.miniatura} alt="..." className="avatar-img rounded-circle" />
                                                                </div>

                                                            </td>
                                                            <td>{projeto.titulo}</td>
                                                            <td>{projeto.descricao.substr(0, 50)}...</td>
                                                            <td>{projeto.tipo}</td>
                                                            <td>
                                                                <button style={{ width: 30, height: 30 }} type="button" class="btn btn-icon btn-primary">
                                                                    <i style={{ fontSize: 13 }} class="fa fa-search "></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}