import React, { useEffect, useState } from 'react';
import Header from '../Componentes/Header'
import Sidebar from '../Componentes/Sidebar'
import firebase from 'firebase';
import moment from 'moment';

export default function Financeiro() {
    const [servicos] = useState({
        auth: firebase.auth(),
        pagamentos: firebase.firestore().collection('pagamentos'),
    })
    const [financeiro, setFinanceiro] = useState([])
    const [valor, setValor] = useState(0)
    const [doacao, setDoacao] = useState(0)
    useEffect(() => {
        servicos.pagamentos
            .where('mes', '==', '1')
            .where('status', '==', 'approved')
            .onSnapshot((snap) => {
                const data = snap.docs.map((d) => d.data())
                setFinanceiro(data)

                // valor total
                let valor = 0;
                let doacao = 0;
                data.forEach((d) => {
                    if (d.pagamento.tipo === 'curso') {
                        valor = d.valor + valor
                    } else {
                        doacao = d.valor + doacao
                    }
                })
                setValor(valor)
                setDoacao(doacao)
            })
    }, [])
    return (
        <div className="dashboard-main-wrapper">
            <Header />
            <Sidebar />
            <div className="main-panel">
                <div className="content">
                    <div className="page-inner">
                        <div className="page-header">
                            <h4 className="page-title">Dashboard - Janeiro</h4>
                            <div className="btn-group btn-group-page-header ml-auto">
                                <button type="button" className="btn btn-light btn-round btn-page-header-dropdown dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-ellipsis-h" />
                                </button>
                                <div className="dropdown-menu">
                                    <div className="arrow" />
                                    <a className="dropdown-item" href="#">Action</a>
                                    <a className="dropdown-item" href="#">Another action</a>
                                    <a className="dropdown-item" href="#">Something else here</a>
                                    <div className="dropdown-divider" />
                                    <a className="dropdown-item" href="#">Separated link</a>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 col-md-4">
                                <div className="card card-stats card-round">
                                    <div className="card-body ">
                                        <div className="row">
                                            <div className="col-5">
                                                <div className="icon-big text-center">
                                                    <i className="flaticon-coins text-success" />
                                                </div>
                                            </div>
                                            <div className="col col-stats">
                                                <div className="numbers">
                                                    <p className="card-category">Total Cursos</p>
                                                    <h4 className="card-title">{valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4">
                                <div className="card card-stats card-round">
                                    <div className="card-body ">
                                        <div className="row">
                                            <div className="col-5">
                                                <div className="icon-big text-center">
                                                    <i className="flaticon-arrows-2" />
                                                </div>
                                            </div>
                                            <div className="col col-stats">
                                                <div className="numbers">
                                                    <p className="card-category">PIX</p>
                                                    <h4 className="card-title">{financeiro.filter((d) => d.pagamento.metodo === 'pix' && d.pagamento.tipo === 'curso').length}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4">
                                <div className="card card-stats card-round">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-5">
                                                <div className="icon-big text-center">
                                                    <i className="flaticon-credit-card" />
                                                </div>
                                            </div>
                                            <div className="col col-stats">
                                                <div className="numbers">
                                                    <p className="card-category">Cartão</p>
                                                    <h4 className="card-title">{financeiro.filter((d) => d.pagamento.metodo === 'cartao' && d.pagamento.tipo === 'curso').length}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4">
                                <div className="card card-stats card-round">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-5">
                                                <div className="icon-big text-center">
                                                    <i className="flaticon-coins text-success" />
                                                </div>
                                            </div>
                                            <div className="col col-stats">
                                                <div className="numbers">
                                                    <p className="card-category">Total Doação</p>
                                                    <h4 className="card-title">{doacao.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4">
                                <div className="card card-stats card-round">
                                    <div className="card-body ">
                                        <div className="row">
                                            <div className="col-5">
                                                <div className="icon-big text-center">
                                                    <i className="flaticon-arrows-2" />
                                                </div>
                                            </div>
                                            <div className="col col-stats">
                                                <div className="numbers">
                                                    <p className="card-category">PIX</p>
                                                    <h4 className="card-title">{financeiro.filter((d) => d.pagamento.metodo === 'pix' && d.pagamento.tipo === 'doacao').length}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4">
                                <div className="card card-stats card-round">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-5">
                                                <div className="icon-big text-center">
                                                    <i className="flaticon-credit-card" />
                                                </div>
                                            </div>
                                            <div className="col col-stats">
                                                <div className="numbers">
                                                    <p className="card-category">Cartão</p>
                                                    <h4 className="card-title">{financeiro.filter((d) => d.pagamento.metodo === 'cartao' && d.pagamento.tipo === 'doacao').length}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div class="card-header">
                                            <div class="card-title">Últimos 10 Pagamentos - Curso</div>
                                        </div>
                                        <table className="table mt-3">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Curso</th>
                                                    <th scope="col">Aluno</th>
                                                    <th scope="col">Data Pagamento</th>
                                                    <th scope="col">Pagamento</th>
                                                    <th scope="col">Valor</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {financeiro.filter((d) => d.pagamento.tipo === 'curso').map((fin) =>
                                                    <tr key={fin.id}>
                                                        <td>{fin.curso.titulo}</td>
                                                        <td>{fin.usuario.nome} {fin.usuario.sobrenome}</td>
                                                        <td>{moment(fin.dataPagamento).format('DD/MM/YYYY HH:mm')}</td>
                                                        <td>
                                                            {fin.pagamento.metodo === 'pix' ?
                                                                <i className="flaticon-arrows-2" />
                                                                :
                                                                <i className="flaticon-credit-card" />
                                                            }
                                                        </td>
                                                        <td>{fin.valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        {financeiro.filter((d) => d.pagamento.tipo === 'curso').length === 0 ?
                                            <p style={{textAlign:'center'}}>Sem pagamentos para cursos</p>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div class="card-header">
                                            <div class="card-title">Últimos 10 Pagamentos - Doações</div>
                                        </div>
                                        <table className="table mt-3">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Doação</th>
                                                    <th scope="col">Parceiro</th>
                                                    <th scope="col">Data Pagamento</th>
                                                    <th scope="col">Pagamento</th>
                                                    <th scope="col">Valor</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {financeiro.filter((d) => d.pagamento.tipo === 'doacao').map((fin) =>
                                                    <tr key={fin.id}>
                                                        <td>{fin.curso.titulo}</td>
                                                        <td>{fin.usuario.nome} {fin.usuario.sobrenome}</td>
                                                        <td>{moment(fin.dataPagamento).format('DD/MM/YYYY HH:mm')}</td>
                                                        <td>
                                                            {fin.pagamento.metodo === 'pix' ?
                                                                <i className="flaticon-arrows-2" />
                                                                :
                                                                <i className="flaticon-credit-card" />
                                                            }
                                                        </td>
                                                        <td>{fin.valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>

                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        {financeiro.filter((d) => d.pagamento.tipo === 'doacao').length === 0 ?
                                            <p style={{ textAlign: 'center' }}>Sem doações para cursos</p>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
