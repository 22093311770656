import React, { useEffect } from 'react';
import Header from '../../../Componentes/Header'
import Sidebar from '../../../Componentes/Sidebar'

export default function Administrador() {
    useEffect(() => {
        // console.log(localStorage.getItem('token'))
        // roles('ALUNOS','Alunos').then((res)=>{
        //     console.log(res)
        // })
    })
    return (
        <div className="dashboard-main-wrapper">
            <Header />
            <Sidebar />
            <div className="main-panel">
                <div className="content">
                    <div className="page-inner">
                        <div className="page-header">
                            <h4 className="page-title">Dashboard</h4>
                            <div className="btn-group btn-group-page-header ml-auto">
                                <button type="button" className="btn btn-light btn-round btn-page-header-dropdown dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-ellipsis-h" />
                                </button>
                                <div className="dropdown-menu">
                                    <div className="arrow" />
                                    <a className="dropdown-item" href="#">Action</a>
                                    <a className="dropdown-item" href="#">Another action</a>
                                    <a className="dropdown-item" href="#">Something else here</a>
                                    <div className="dropdown-divider" />
                                    <a className="dropdown-item" href="#">Separated link</a>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 col-md-3">
                                <div className="card card-stats card-round">
                                    <div className="card-body ">
                                        <div className="row align-items-center">
                                            <div className="col-icon">
                                                <div className="icon-big text-center icon-primary bubble-shadow-small">
                                                    <i className="fas fa-users" />
                                                </div>
                                            </div>
                                            <div className="col col-stats ml-3 ml-sm-0">
                                                <div className="numbers">
                                                    <p className="card-category">Usuários</p>
                                                    <h4 className="card-title">124</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3">
                                <div className="card card-stats card-round">
                                    <div className="card-body">
                                        <div className="row align-items-center">
                                            <div className="col-icon">
                                                <div className="icon-big text-center icon-info bubble-shadow-small">
                                                    <i className="far fa-newspaper" />
                                                </div>
                                            </div>
                                            <div className="col col-stats ml-3 ml-sm-0">
                                                <div className="numbers">
                                                    <p className="card-category">Escolas</p>
                                                    <h4 className="card-title">2</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3">
                                <div className="card card-stats card-round">
                                    <div className="card-body">
                                        <div className="row align-items-center">
                                            <div className="col-icon">
                                                <div className="icon-big text-center icon-success bubble-shadow-small">
                                                    <i className="far fa-users" />
                                                </div>
                                            </div>
                                            <div className="col col-stats ml-3 ml-sm-0">
                                                <div className="numbers">
                                                    <p className="card-category">Professores</p>
                                                    <h4 className="card-title">20</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3">
                                <div className="card card-stats card-round">
                                    <div className="card-body">
                                        <div className="row align-items-center">
                                            <div className="col-icon">
                                                <div className="icon-big text-center icon-secondary bubble-shadow-small">
                                                    <i className="far fa-check-circle" />
                                                </div>
                                            </div>
                                            <div className="col col-stats ml-3 ml-sm-0">
                                                <div className="numbers">
                                                    <p className="card-category">Aulas</p>
                                                    <h4 className="card-title">576</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        
                        
                    </div>
                </div>
            </div>


        </div>
    );
}
