import React, { useEffect, useState } from 'react';
import firebase from 'firebase';
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom';

// Componentes
import Sidebar from '../../Componentes/Sidebar'
import Header from '../../Componentes/Header';
import moment from 'moment';

export default function Posts() {
    const [servicos] = useState({
        auth: firebase.auth(),
        categorias: firebase.firestore().collection('categoriaCursos'),
        cursos: firebase.firestore().collection('cursos'),
    })
    const [categoriasCursos, setCategoriasCursos] = useState([])
    const [id, setId] = useState('')
    const [categoria, setCategoria] = useState('')
    const [status, setStatus] = useState('')
    const [imagem, setImagem] = useState('');
    const [upload, setUpload] = useState('');
    const [filtro, setFiltro] = useState('')

    useEffect(() => {
        servicos.categorias.onSnapshot((snap) => {
            const data = snap.docs.map((d) => d.data())
            setCategoriasCursos(data)
        })
    }, [])
    function detalhe(dados) {
        setId(dados.id)
        setCategoria(dados.categoria)
        setStatus(dados.status)
        setImagem(dados.url)
    }
    function limpaCategoria() {
        setId('')
        setCategoria('')
        setStatus('')
        setImagem('')
        setUpload('')
    }
    function cadastraImagem(id) {
        Swal.close();
        mensagemLoading('Enviando Imagem...')
        var uploadTask = firebase.storage().ref().child('categoriasCurso/' + id + '.' + imagem[0].type.replace('image/', '')).put(imagem[0], { contentType: imagem[0].type });
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.categorias.doc(id).update({
                    url: downloadURL,
                    extensao:imagem[0].type.replace('image/', '')
                }).then(() => {
                    mensagemSucesso('Categoria cadastrada com sucesso')
                })
            });
        });
    }

    function cadastraCategoria() {
        if (!imagem) {
            mensagemAlerta('Por favor escolha a imagem')
            return
        }
        if (!categoria) {
            mensagemAlerta('Por favor digite a categoria')
            return
        }
        if (!status) {
            mensagemAlerta('Por favor escolha o status')
            return
        }
        if (id) {
            mensagemLoading('Atualizando categoria..')
            servicos.categorias.doc(id).update({
                categoria: categoria,
                status: status,
                dataAtualizacao: moment().format('YYYY-MM-DD - HH:mm')
            }).then((res) => {
                if (upload) {
                    cadastraImagem(id)
                } else {
                    mensagemSucesso('Categoria atualizada com sucesso')
                }
            })
        } else {
            mensagemLoading('Cadastrando categoria..')
            servicos.categorias.add({
                categoria: categoria,
                status: status,
                dataCadastro: moment().format('YYYY-MM-DD - HH:mm')
            }).then((res) => {
                servicos.categorias.doc(res.id).update({
                    id: res.id
                }).then(() => {
                    cadastraImagem(res.id)
                })
            })
        }
    }

    function excluiCategoria(dados){
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja excluir a categoria ' + dados.categoria + ' ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                mensagemLoading('Excluindo categoria...')
                servicos.cursos.where('idCategoria','==',dados.id).get().then((res)=>{
                    if(res.size > 0){
                        mensagemAlerta('Categoria não pode ser excluída, pois está vinculada a um ou vários cursos')
                    }else{
                        firebase.storage().ref().child('categoriasCurso/' + dados.id + '.' + dados.extensao).delete().then(()=>{
                            servicos.categorias.doc(dados.id).delete().then(()=>{
                                mensagemSucesso('Categoria excluída com sucesso!')
                            })
                        })
                    }
                })
            }
        });
    }

    function filtrar() {
        if (!filtro) {
            mensagemAlerta('Escolha o filtro')
            return
        }
        mensagemLoading('Carregando Filtro')
        servicos.cursos.where('status', '==', filtro).get().then((snap) => {
            const data = snap.docs.map((d) => d.data())
            categoriasCursos(data)
            Swal.close()
        })

    }

    // Inputs
    const handleFiltro = (event) => {
        event.persist();
        setFiltro(event.target.value);
    }
    const handleImagem = (event) => {
        event.persist();
        setImagem(event.target.files)
        setUpload(URL.createObjectURL(event.target.files[0]))
    }
    const handleCategoria = (event) => {
        event.persist();
        setCategoria(event.target.value)
    }
    const handleStatus = (event) => {
        event.persist();
        setStatus(event.target.value)
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            limpaCategoria()
        })
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="dashboard-main-wrapper">
            <Header />
            <Sidebar />
            <div className="main-panel">
                <div className="content">
                    <div className="page-inner">
                        <div className="page-header">
                            <h4 className="page-title">Categorias de Cursos</h4>
                            <div className="btn-group btn-group-page-header ml-auto">
                                <button type="button" className="btn btn-light btn-round btn-page-header-dropdown dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-ellipsis-h" />
                                </button>
                                <div className="dropdown-menu">
                                    <div className="arrow" />
                                    <a className="dropdown-item" href="/cadastroCurso">Novo Curso</a>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 col-md-6">
                                <div className="card card-stats card-round">
                                    <div className="card-body ">
                                        <div className="row align-items-center">
                                            <div className="col-icon">
                                                <div className="icon-big text-center icon-success bubble-shadow-small">
                                                    <i className="fas fa-check" />
                                                </div>
                                            </div>
                                            <div className="col col-stats ml-3 ml-sm-0">
                                                <div className="numbers">
                                                    <p className="card-category">Ativos</p>
                                                    <h4 className="card-title">{categoriasCursos.filter((d) => d.status === 'ATIVO').length}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6">
                                <div className="card card-stats card-round">
                                    <div className="card-body">
                                        <div className="row align-items-center">
                                            <div className="col-icon">
                                                <div className="icon-big text-center icon-danger bubble-shadow-small">
                                                    <i className="far fa-window-close" />
                                                </div>
                                            </div>
                                            <div className="col col-stats ml-3 ml-sm-0">
                                                <div className="numbers">
                                                    <p className="card-category">Inativos</p>
                                                    <h4 className="card-title">{categoriasCursos.filter((d) => d.status === 'INATIVO').length}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div class="card-header">
                                            <div class="card-title">Lista de Categorias</div>
                                        </div>
                                        <div className="card-sub">
                                            Aqui estão listadas todas as categorias de cursos
                                        </div>
                                        <div className="card-body row">
                                            <div className="form-group col-md-6">
                                                <label >Imagem</label>
                                                <input onChange={handleImagem} type="file" className="form-control" id="email2" />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label >Categoria</label>
                                                <input value={categoria} onChange={handleCategoria} type="email" className="form-control" id="email2" />
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label >Status</label>
                                                <select className="form-control" onChange={handleStatus}>
                                                    <option>Selecione</option>
                                                    <option value='ATIVO' selected={id && status === 'ATIVO' ? 'selected' : null}>ATIVO</option>
                                                    <option value={'INATIVO'} selected={id && status === 'INATIVO' ? 'selected' : null}>INATIVO</option>
                                                </select>
                                            </div>
                                            {id ?
                                                <>
                                                    <div className="form-group col-md-2">
                                                        <button onClick={() => cadastraCategoria()} className="btn btn-success">Atualizar</button>
                                                    </div>
                                                    <div className="form-group col-md-2">
                                                        <button onClick={() => limpaCategoria()} className="btn btn-danger">Descartar</button>
                                                    </div>
                                                </>
                                                :
                                                <div className="form-group col-md-2">
                                                    <button onClick={() => cadastraCategoria()} className="btn btn-success">Cadastrar</button>

                                                </div>
                                            }

                                        </div>
                                        <table className="table mt-3">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Avatar</th>
                                                    <th scope="col">Categoria</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Editar</th>
                                                    <th scope="col">Excluir</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {categoriasCursos.map((curso) =>
                                                    <tr>
                                                        <td>
                                                            <div className="avatar avatar-sm">
                                                                <img src={curso.url} alt="..." className="avatar-img rounded-circle" />
                                                            </div>

                                                        </td>
                                                        <td>{curso.categoria}</td>
                                                        <td>
                                                            {curso.status === 'ATIVO' ?
                                                                <button style={{ width: 30, height: 30 }} type="button" class="btn btn-icon btn-success">
                                                                    <i style={{ fontSize: 13 }} class="fa fa-check "></i>
                                                                </button>
                                                                : curso.status === 'RASCUNHO' ?
                                                                    <button style={{ width: 30, height: 30 }} type="button" class="btn btn-icon btn-warning">
                                                                        <i style={{ fontSize: 13 }} class="fas fa-exclamation-circle"></i>
                                                                    </button>
                                                                    :
                                                                    <button style={{ width: 30, height: 30 }} type="button" class="btn btn-icon btn-danger">
                                                                        <i style={{ fontSize: 13 }} class="far fa-window-close"></i>
                                                                    </button>
                                                            }
                                                        </td>
                                                        <td>
                                                            <button onClick={() => detalhe(curso)} style={{ width: 30, height: 30 }} type="button" class="btn btn-icon btn-warning">
                                                                <i style={{ fontSize: 13 }} class="fa fa-edit "></i>
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <button onClick={()=> excluiCategoria(curso)} style={{ width: 30, height: 30 }} type="button" class="btn btn-icon btn-danger">
                                                                <i style={{ fontSize: 13 }} class="fa fa-trash "></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}