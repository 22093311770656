import React, { useEffect, useState } from 'react';
import firebase from 'firebase';
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom';

// Componentes
import Sidebar from '../../Componentes/Sidebar'
import Header from '../../Componentes/Header';
import moment from 'moment';

export default function Posts() {
    const [servicos] = useState({
        auth: firebase.auth(),
        blog: firebase.firestore().collection('blog'),
    })
    const [blog, setBlog] = useState([])
    const [filtro, setFiltro] = useState('')

    useEffect(() => {
        servicos.blog.get().then((snap) => {
            const data = snap.docs.map((d) => d.data())
            setBlog(data)
        })
    }, [])

    function filtrar() {
        if (!filtro) {
            mensagemAlerta('Escolha o filtro')
            return
        }
        mensagemLoading('Carregando Filtro')
        servicos.blog.where('status', '==', filtro).get().then((snap) => {
            const data = snap.docs.map((d) => d.data())
            setBlog(data)
            Swal.close()
        })

    }

    // Inputs
    const handleFiltro = (event) => {
        event.persist();
        setFiltro(event.target.value);
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <>
            <Sidebar />
            <div className="all-content-wrapper">
                <Header />
                <div className="analytics-sparkle-area">
                    <div className="container-fluid">
                        <div className="breadcome-list" style={{ marginTop: 80 }}>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12">
                                    <h1>Blog</h1>
                                </div>
                            </div>
                        </div>
                        <div className="product-status mg-b-15">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="product-status-wrap drp-lst">
                                            <h4>Lista de Posts</h4>
                                            <div class="add-product">
                                                <a href="/cadastroPost">Novo Post</a>
                                            </div>
                                            <div className="row">
                                                <div>
                                                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                                        <div className="form-group">
                                                            <select name="gender" className="form-control" onChange={handleFiltro}>
                                                                <option value="">Selecione o Filtro</option>
                                                                <option value="ATIVO" >ATIVO</option>
                                                                <option value="RASCUNHO" >RASCUNHO</option>
                                                                <option value="INATIVO" >INATIVO</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                                        <button onClick={() => filtrar()} style={{ height: 40 }} type="button" class="btn btn-custon-rounded-four btn-success">Filtrar</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="asset-inner">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <th>Avatar</th>
                                                            <th>Título</th>
                                                            <th>Descrição</th>
                                                            <th>Categoria</th>
                                                            <th>Usuário</th>
                                                            <th>Status</th>
                                                            <th>Ações</th>
                                                        </tr>
                                                        {blog.map((post) =>
                                                            <tr key={post.id}>
                                                                <td><img src={post.miniatura} alt="" /></td>
                                                                <td>{post.titulo}</td>
                                                                <td>{post.descricao}</td>
                                                                <td>{post.categoria.categoria}</td>
                                                                <td>{post.usuario.nome}</td>
                                                                <td>
                                                                    {post.status === 'ATIVO' ?
                                                                        <button type="button" class="btn btn-custon-four btn-success"><i class="fa fa-check edu-checked-pro" aria-hidden="true"></i></button>
                                                                        :
                                                                        <button type="button" class="btn btn-custon-four btn-danger"><i class="fa fa-close edu-checked-pro" aria-hidden="true"></i></button>
                                                                    }

                                                                </td>
                                                                <td>
                                                                    <button onClick={()=> window.location = '/cadastroPost?'+post.id} data-toggle="tooltip" title="Edit" className="pd-setting-ed"><i className="fa fa-pencil-square-o" aria-hidden="true" /></button>
                                                                    <button data-toggle="tooltip" title="Trash" className="pd-setting-ed"><i className="fa fa-trash-o" aria-hidden="true" /></button>

                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {blog.length === 0 ?
                                                    <p style={{ textAlign: 'center', marginTop: 20 }}>Sem posts cadastrados</p>
                                                    :
                                                    null
                                                }

                                            </div>
                                            {blog.length > 10 ?
                                                <div className="custom-pagination">
                                                    <nav aria-label="Page navigation example">
                                                        <ul className="pagination">
                                                            <li className="page-item"><a className="page-link" href="#">Previous</a></li>
                                                            <li className="page-item"><a className="page-link" href="#">1</a></li>
                                                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                            <li className="page-item"><a className="page-link" href="#">Next</a></li>
                                                        </ul>
                                                    </nav>
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}