import React, { useEffect, useState } from 'react';
import firebase from 'firebase';
import Swal from 'sweetalert2'
import SunEditor from 'suneditor-react';
// Componentes
import Sidebar from '../../Componentes/Sidebar'
import Header from '../../Componentes/Header';
import moment from 'moment';

import 'suneditor/dist/css/suneditor.min.css';
import { Link } from 'react-router-dom';


export default function CadsatroPost(props) {
    const [dado] = useState(props.location.state ? props.location.state.curso : '')
    const [servicos] = useState({
        auth: firebase.auth(),
        cursos: firebase.firestore().collection('cursos'),
        usuario: firebase.firestore().collection('usuarios'),
        categorias: firebase.firestore().collection('categoriaCursos'),
    })
    const [usuario, setUsuario] = useState({})
    const [categorias, setCategorias] = useState([])
    const [professores, setProfessores] = useState([])
    const [optionsSun] = useState({
        mode: "classic",
        rtl: false,
        katex: "window.katex",
        videoFileInput: false,
        tabDisable: false,
        buttonList: [
            [
                "undo",
                "redo",
                "font",
                "fontSize",
                "formatBlock",
                "paragraphStyle",
                "blockquote",
                "bold",
                "underline",
                "italic",
                "strike",
                "subscript",
                "superscript",
                "fontColor",
                "hiliteColor",
                "textStyle",
                "removeFormat",
                "outdent",
                "indent",
                "align",
                "horizontalRule",
                "list",
                "lineHeight",
                "table",
                "link",
                "image",
                "video",
                "audio",
                "math",
                "imageGallery",
                "showBlocks",
                "codeView",
                "preview",
                "print",
                "save",
                "template"
            ]
        ],
    })

    // Curso
    const [titulo, setTitulo] = useState(dado.id ? dado.titulo : '')
    const [descricao, setDescricao] = useState(dado.id ? dado.descricao : '')
    const [categoria, setCategoria] = useState({})
    const [base, setBase] = useState(dado.id ? dado.base : '')
    const [qtdHoras, setQtdHoras] = useState(dado.id ? dado.horas : 0)
    const [valor, setValor] = useState(dado.id ? dado.valor : 0)
    const [conteudo, setConteudo] = useState(dado.id ? dado.conteudo : '')
    const [professor, setProfessor] = useState({})
    const [status, setStatus] = useState(dado.id ? dado.status : '')

    //Requisitos
    const [requisitos, setRequisitos] = useState([])
    const [requisito, setRequisito] = useState('')

    // Módulos
    const [modulos, setModulos] = useState([])
    const [posicao, setPosicao] = useState(0)
    const [modulo, setModulo] = useState('')
    const [statusModulo, setStatusModulo] = useState('')

    var metadata = { contentType: 'image/jpeg' };
    const [miniatura, setMiniatura] = useState(dado.id ? dado.miniatura : '');
    const [uploadMiniatura, setUploadMiniatura] = useState('');
    const [imagem, setImagem] = useState(dado.id ? dado.imagem : '');
    const [uploadImagem, setUploadImagem] = useState('');

    useEffect(() => {
        servicos.auth.onAuthStateChanged((user) => {
            if (user) {
                servicos.usuario.doc(user.uid).get().then((u) => {
                    setUsuario(u.data())
                })
            }
            servicos.categorias.get().then((res) => {
                const data = res.docs.map((d) => d.data())
                setCategorias(data)
                if (dado.id) {
                    setCategoria(data.filter((d) => d.id == dado.idCategoria)[0])
                }
            })
            servicos.usuario.where('usuario', '==', 'PROFESSOR').where('status', '==', 'ATIVO').get().then((res) => {
                const data = res.docs.map((d) => d.data())
                setProfessores(data)
                if (dado.id) {
                    setProfessor(data.filter((d) => d.id == dado.professor.id)[0])
                }
            })
            if (dado.id) {
                servicos.cursos.doc(dado.id).collection('requisitos').onSnapshot((res) => {
                    const data = res.docs.map((d) => d.data())
                    setRequisitos(data)
                })
            }
            if (dado.id) {
                servicos.cursos.doc(dado.id).collection('modulos').onSnapshot((res) => {
                    const data = res.docs.map((d) => d.data())
                    setModulos(data)
                })
            }
        })

    }, [])

    function cadastraRequisito() {
        if (!requisito) {
            mensagemAlerta('Digite o requisito')
            return
        }
        mensagemLoading('Cadastrando requisito..')
        servicos.cursos.doc(dado.id).collection('requisitos').add({
            requisito: requisito,
            dataCadastro: moment().format('YYYY-MM-DD HH:mm')
        }).then((res) => {
            servicos.cursos.doc(dado.id).collection('requisitos').doc(res.id).update({
                id: res.id
            }).then(() => {
                Swal.fire('Sucesso', 'Requisito cadastrado com sucesso', 'success').then(() => {
                    setRequisito('')
                })
            })
        })
    }

    function cadastraModulo() {
        if (!posicao) {
            mensagemAlerta('Digite a posição do módulo')
            return
        }
        if (!modulo) {
            mensagemAlerta('Digite o titulo do módulo')
            return
        }
        if (!statusModulo) {
            mensagemAlerta('Escolha o status do módulo')
            return
        }
        mensagemLoading('Cadastrando Módulo..')
        servicos.cursos.doc(dado.id).collection('modulos').where('posicao', '==', parseInt(posicao)).get().then((res) => {
            if (res.size > 0) {
                mensagemAlerta('Esta posição já está cadastrada na lista de módulos')
            } else {
                servicos.cursos.doc(dado.id).collection('modulos').add({
                    titulo: modulo,
                    posicao: parseFloat(posicao),
                    status: statusModulo,
                    dataCadastro: moment().format('YYYY-MM-DD HH:mm')
                }).then((res) => {
                    servicos.cursos.doc(dado.id).collection('modulos').doc(res.id).update({
                        id: res.id
                    }).then(() => {
                        Swal.fire('Sucesso', 'Módulo cadastrado com sucesso! Clique no módulo para cadastrar as aulas', 'success').then(() => {
                            setModulo('')
                            setPosicao(0)
                            setStatusModulo('')
                        })
                    })
                })
            }
        })
    }

    function removeRequisito(req) {
        servicos.cursos.doc(dado.id).collection('requisitos').doc(req.id).delete();
    }

    function cadastrar() {
        if (!miniatura) {
            mensagemAlerta('Escolha a imagem da miniatura')
            return
        }
        if (!imagem) {
            mensagemAlerta('Escolha a imagem Principal')
            return
        }
        if (!titulo) {
            mensagemAlerta('Digite o Titulo')
            return
        }
        if (!descricao) {
            mensagemAlerta('Digite a Descrição')
            return
        }
        if (!categoria.id) {
            mensagemAlerta('Escolha a Categoria')
            return
        }
        if (!base) {
            mensagemAlerta('Curso será base para outros cursos')
            return
        }
        if (!valor || valor === 0) {
            mensagemAlerta('Digite o valor do curso')
            return
        }

        if (!conteudo) {
            mensagemAlerta('Digite o conteúdo')
            return
        }
        if (!professor.id) {
            mensagemAlerta('Escolha o professor')
            return
        }
        if (dado.id) {
            mensagemLoading('Atualizando Curso..')
            servicos.cursos.doc(dado.id).update({
                titulo: titulo,
                base: base,
                descricao: descricao,
                conteudo: conteudo,
                status: status,
                usuario: {
                    nome: usuario.nome,
                    id: usuario.id,
                    email: usuario.email
                },
                dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
            }).then((snap) => {
                if (uploadMiniatura) {
                    cadastraMiniatua(dado.id)
                } else if (uploadImagem) {
                    cadastraImagem(dado.id)
                } else {
                    mensagemSucesso('Curso atualizado com sucesso!')
                }
            })
        } else {
            mensagemLoading('Cadastrando Curso..')
            servicos.cursos.add({
                titulo: titulo,
                descricao: descricao,
                categoria: categoria.categoria,
                idCategoria: categoria.id,
                base: base,
                classificacao: parseInt(0),
                alunos: parseInt(0),
                horas: parseFloat(qtdHoras),
                conteudo: conteudo,
                status: 'RASCUNHO',
                professor: {
                    nome: professor.nome,
                    id: professor.id,
                    avatar: professor.avatar
                },
                usuario: {
                    nome: usuario.nome,
                    id: usuario.id,
                    email: usuario.email
                },
                valor: parseFloat(valor),
                dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
            }).then((snap) => {
                servicos.cursos.doc(snap.id).update({
                    id: snap.id
                }).then(() => {
                    cadastraMiniatua(snap.id)
                })
            })
        }


    }

    function cadastraMiniatua(id) {
        Swal.close();
        mensagemLoading('Enviando a miniatura...')
        var uploadTask = firebase.storage().ref().child('cursos/' + id + '/miniatura.' + miniatura[0].type.replace('image/', '')).put(miniatura[0], { contentType: miniatura[0].type });
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.cursos.doc(id).update({
                    miniatura: downloadURL,
                    extensaoMiniatura: miniatura[0].type.replace('image/', '')
                }).then(() => {
                    if (uploadImagem) {
                        cadastraImagem(id)
                    } else {
                        mensagemSucesso('Miniatura atualizada com sucesso')
                    }

                })
            });
        });
    }

    function cadastraImagem(id) {
        Swal.close();
        mensagemLoading('Enviando a imagem principal...')
        var uploadTask = firebase.storage().ref().child('cursos/' + id + '/imagem.' + imagem[0].type.replace('image/', '')).put(imagem[0], { contentType: imagem[0].type });
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.cursos.doc(id).update({
                    imagem: downloadURL,
                    extensaoImagem: imagem[0].type.replace('image/', '')
                }).then(() => {
                    mensagemSucesso('Curso cadastrado com sucesso')
                })
            });
        });
    }

    // Inputs
    const handleMiniatura = (event) => {
        event.persist();
        setMiniatura(event.target.files)
        setUploadMiniatura(URL.createObjectURL(event.target.files[0]))
    }
    const handleImagem = (event) => {
        event.persist();
        setImagem(event.target.files)
        setUploadImagem(URL.createObjectURL(event.target.files[0]))
    }
    const handleTitulo = (event) => {
        event.persist();
        setTitulo(event.target.value);
    }
    const handleDescricao = (event) => {
        event.persist();
        setDescricao(event.target.value);
    }
    const handleCategoria = (event) => {
        event.persist();
        setCategoria(categorias[event.target.value])
    }
    const handleBase = (event) => {
        event.persist();
        setBase(event.target.value)
    }
    const handleQtdHoras = (event) => {
        event.persist();
        setQtdHoras(event.target.value)
    }
    const handleValor = (event) => {
        event.persist();
        setValor(event.target.value)
    }
    const handleConteudo = (event) => {
        setConteudo(event);
    }
    const handleProfessor = (event) => {
        event.persist();
        setProfessor(professores[event.target.value])
    }

    // Requisitos
    const handleRequisito = (event) => {
        event.persist();
        setRequisito(event.target.value);
    }

    // Modulos
    const handlePosicao = (event) => {
        event.persist();
        setPosicao(event.target.value);
    }
    const handleModulo = (event) => {
        event.persist();
        setModulo(event.target.value);
    }
    const handleStatusModulo = (event) => {
        event.persist();
        setStatusModulo(event.target.value);
    }

    // Mensagens
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.history.back()
        })
    }
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="dashboard-main-wrapper">
            <Header />
            <Sidebar />
            <div className="main-panel">
                <div className="content">
                    <div className="page-inner">
                        <div className="page-header">
                            <h4 className="page-title">{dado.id ? titulo : 'Novo Curso'}</h4>
                            <ul className="breadcrumbs">

                            </ul>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card card-profile card-secondary">
                                    <div className="card-header" style={{ backgroundImage: 'url("../assets/img/blogpost.jpg")' }}>
                                        <div className="profile-picture">
                                            <div className="avatar avatar-xl">
                                                {uploadMiniatura ?
                                                    <img src={uploadMiniatura} alt="..." className="avatar-img rounded-circle" />
                                                    : miniatura ?
                                                        <img src={miniatura} alt="..." className="avatar-img rounded-circle" />
                                                        :
                                                        <img src="https://www.kindpng.com/picc/m/10-109847_admin-icon-hd-png-download.png" alt="..." className="avatar-img rounded-circle" />
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="user-profile text-center">
                                            <div className="name">Miniatura</div>
                                            <div className="view-profile">
                                                <input onChange={handleMiniatura} type="file" className="form-control" id="email2" />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="card card-profile card-secondary">
                                    <div className="card-header" style={{ backgroundImage: 'url("../assets/img/blogpost.jpg")' }}>
                                        <div className="profile-picture">
                                            <div className="avatar avatar-xl">
                                                {uploadImagem ?
                                                    <img src={uploadImagem} alt="..." className="avatar-img rounded-circle" />
                                                    : imagem ?
                                                        <img src={imagem} alt="..." className="avatar-img rounded-circle" />
                                                        :
                                                        <img src="https://www.kindpng.com/picc/m/10-109847_admin-icon-hd-png-download.png" alt="..." className="avatar-img rounded-circle" />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="user-profile text-center">
                                            <div className="name">Imagem Principal</div>
                                            <div className="view-profile">
                                                <input onChange={handleImagem} type="file" className="form-control" id="email2" />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="col-md-8">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="card-title">Dados do Curso</div>
                                    </div>
                                    <div className="card-body row">
                                        <div className="form-group col-md-6">
                                            <label >Títutlo</label>
                                            <input onChange={handleTitulo} value={titulo} type="email" className="form-control" id="email2" />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label >Descrição</label>
                                            <input onChange={handleDescricao} value={descricao} type="email" className="form-control" id="email2" />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label>Categoria</label>
                                            <select className="form-control" onChange={handleCategoria}>
                                                <option>Selecione</option>
                                                {categorias.map((c, index) =>
                                                    <option value={index} selected={dado.id && categoria.id === c.id ? 'selected' : null}>{c.categoria}</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label >Curso Base?</label>
                                            <select className="form-control" onChange={handleBase}>
                                                <option>Selecione</option>
                                                <option value={'SIM'} selected={dado.id && dado.base === 'SIM' ? 'selected' : null}>Sim</option>
                                                <option value={'NAO'} selected={dado.id && dado.base === 'NAO' ? 'selected' : null}>Não</option>
                                            </select>
                                        </div>
                                        <div className="form-group col-md-2">
                                            <label >Qtd horas</label>
                                            <input onChange={handleQtdHoras} value={qtdHoras} type="number" className="form-control" id="email2" />
                                        </div>
                                        <div className="form-group col-md-2">
                                            <label >Valor do Curso</label>
                                            <input onChange={handleValor} value={valor} type="number" className="form-control" id="email2" />
                                        </div>
                                        <div className="form-group col-md-12">
                                            <SunEditor
                                                setOptions={optionsSun}
                                                lang="pt_br"
                                                placeholder="Conteúdo"
                                                onChange={handleConteudo}
                                                setContents={conteudo}
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Professor(a)</label>
                                            <select className="form-control" onChange={handleProfessor}>
                                                <option>Selecione</option>
                                                {professores.map((c, index) =>
                                                    <option value={index} selected={dado.id && professor.id === c.id ? 'selected' : null}>{c.nome} - {c.cpf}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <div className="card-title">Requisitos</div>
                                    </div>
                                    <div className="card-body row">
                                        {dado.id ?
                                            <>
                                                <div className="form-group col-md-10">
                                                    <label >Requisito</label>
                                                    <input value={requisito} onChange={handleRequisito} type="email" className="form-control" id="email2" />
                                                </div>
                                                <div className="form-group col-md-2">
                                                    <button style={{ marginTop: 25 }} onClick={() => cadastraRequisito()} className="btn btn-success">Cadastrar</button>
                                                </div>
                                            </>
                                            :
                                            <div className="card-sub" >
                                                Você só poderá cadastrar os requisitos após cadastrar o curso
                                            </div>
                                        }
                                        <table className="table mt-3">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Requisito</th>
                                                    <th scope="col">Excluir</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {requisitos.map((requisito) =>
                                                    <tr>
                                                        <td>{requisito.requisito}</td>
                                                        <td>
                                                            <button onClick={() => removeRequisito(requisito)} style={{ width: 30, height: 30 }} type="button" class="btn btn-icon btn-danger">
                                                                <i style={{ fontSize: 13 }} class="fa fa-trash "></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )}

                                            </tbody>
                                            {requisitos.length === 0 ?
                                                <p>Sem requisitos cadastrados</p>
                                                :
                                                null
                                            }
                                        </table>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <div className="card-title">Módulos do Curso</div>
                                    </div>
                                    <div className="card-body row">
                                        {dado.id ?
                                            <>
                                                <div className="form-group col-md-2">
                                                    <label >Posição</label>
                                                    <input value={posicao} onChange={handlePosicao} type="number" className="form-control" id="email2" />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label >Módulo</label>
                                                    <input value={modulo} onChange={handleModulo} type="email" className="form-control" id="email2" />
                                                </div>
                                                <div className="form-group col-md-2">
                                                    <label >Status</label>
                                                    <select className="form-control" onChange={handleStatusModulo}>
                                                        <option  >Escoha</option>
                                                        <option value={'ATIVO'} >Ativo</option>
                                                        <option value={'INATIVO'} >Inativo</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-2">
                                                    <button style={{ marginTop: 25 }} onClick={() => cadastraModulo()} className="btn btn-success">Cadastrar</button>
                                                </div>
                                            </>
                                            :
                                            <div className="card-sub">
                                                Você só poderá cadastrar módulos após cadastrar o curso
                                            </div>
                                        }

                                        <table className="table mt-3">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Posição</th>
                                                    <th scope="col">Módulo</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Aulas</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {modulos.sort((a, b) => {
                                                    if (a.posicao < b.posicao)
                                                        return -1;
                                                    if (a.posicao > b.posicao)
                                                        return 1;
                                                    return 0;
                                                }).map((mod) =>
                                                    <tr>
                                                        <td>{mod.posicao}</td>
                                                        <td>{mod.titulo}</td>
                                                        <td>
                                                            {mod.status === 'ATIVO' ?
                                                                <button style={{ width: 30, height: 30 }} type="button" class="btn btn-icon btn-success">
                                                                    <i style={{ fontSize: 13 }} class="fa fa-check "></i>
                                                                </button>
                                                                : mod.status === 'RASCUNHO' ?
                                                                    <button style={{ width: 30, height: 30 }} type="button" class="btn btn-icon btn-warning">
                                                                        <i style={{ fontSize: 13 }} class="fas fa-exclamation-circle"></i>
                                                                    </button>
                                                                    :
                                                                    <button style={{ width: 30, height: 30 }} type="button" class="btn btn-icon btn-danger">
                                                                        <i style={{ fontSize: 13 }} class="far fa-window-close"></i>
                                                                    </button>
                                                            }
                                                        </td>
                                                        <td>
                                                            <Link to={{ pathname: '/aulas', state: { modulo: mod, curso:dado } }} style={{ width: 30, height: 30 }} type="button" class="btn btn-icon btn-primary">
                                                                <i style={{ fontSize: 13 }} class="fa fa-search "></i>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                )}

                                            </tbody>
                                            {modulos.length === 0 ?
                                                <p>Sem módulos cadastrados</p>
                                                :
                                                null
                                            }
                                        </table>
                                    </div>
                                </div>

                                <div className="card-action">
                                    <button onClick={() => cadastrar()} className="btn btn-success">Cadastrar</button>
                                    <button onClick={() => window.history.back()} style={{ marginLeft: 10 }} className="btn btn-danger">Cancelar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
}