import React, { useEffect, useState } from 'react';
import firebase from 'firebase';
import Swal from 'sweetalert2'

// Componentes
import Sidebar from '../../Componentes/Sidebar'
import Header from '../../Componentes/Header';
import moment from 'moment';
export default function CategoriasDeBlog() {
    const [servicos] = useState({
        auth: firebase.auth(),
        categorias: firebase.firestore().collection('categoriasBlog'),
        usuarios: firebase.firestore().collection('usuarios'),
    })
    const [categorias, setCategorias] = useState([])
    const [usuario, setUsuario] = useState({})
    const [categoria, setCategoria] = useState({})

    const [nome, setNome] = useState('')
    const [status, setStatus] = useState('')

    useEffect(() => {
        servicos.auth.onAuthStateChanged((snap) => {
            if (snap) {
                servicos.usuarios.doc(snap.uid).get().then((u) => {
                    setUsuario(u.data())
                })
            }
        })
        servicos.categorias.onSnapshot((snap) => {
            const data = snap.docs.map((d) => d.data())
            setCategorias(data)
        })
    }, [])

    function cadastraCategoria() {
        if (!nome) {
            mensagemAlerta('Digite o nome')
            return
        }
        if (!status) {
            mensagemAlerta('Selecione o status')
            return
        }
        if (categoria.id) {
            mensagemLoading('Atualizando categoria')
            servicos.categorias.doc(categoria.id).update({
                nome: nome,
                status: status,
                dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                usuario: {
                    nome: usuario.nome,
                    id: usuario.id,
                    email: usuario.email
                }
            }).then((ref) => {
                mensagemSucesso('Categoria atualizada com sucesso')
            })
        } else {
            mensagemLoading('Cadastrando categoria')
            servicos.categorias.add({
                nome: nome,
                status: status,
                dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                usuario: {
                    nome: usuario.nome,
                    id: usuario.id,
                    email: usuario.email
                }
            }).then((ref) => {
                servicos.categorias.doc(ref.id).update({
                    id: ref.id
                }).then(() => {
                    mensagemSucesso('Categoria cadastrada com sucesso')
                })
            })
        }

    }

    function detalhe(item) {
        setCategoria(item)
        setNome(item.nome)
        setStatus(item.status)
    }

    // Inputs
    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleStatus = (event) => {
        event.persist();
        setStatus(event.target.value);
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            setNome('')
            setStatus('')
            setCategoria({})
        })
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <>
            <Sidebar />
            <div className="all-content-wrapper">
                <Header />
                <div className="analytics-sparkle-area">
                    <div className="container-fluid">
                        <div className="breadcome-list" style={{ marginTop: 80 }}>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12">
                                    <h1>Categoria de Blog</h1>
                                </div>
                            </div>
                        </div>
                        <div className="product-status mg-b-15">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="product-status-wrap drp-lst">
                                            <h4>Lista de Departamentos</h4>
                                            <div className="row">
                                                <div>
                                                    <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                                        <div className="form-group">
                                                            <input value={nome} onChange={handleNome} name="name" type="text" className="form-control" placeholder="Name" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                                        <div className="form-group">
                                                            <select name="gender" className="form-control" onChange={handleStatus}>
                                                                <option value="">Selecione o Status</option>
                                                                <option value="ATIVO" selected={status === 'ATIVO' ? 'selected' : null}>ATIVO</option>
                                                                <option value="INATIVO" selected={status === 'INATIVO' ? 'selected' : null}>INATIVO</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                                        {categoria.id ?
                                                            <button onClick={() => cadastraCategoria()} style={{ height: 40 }} type="button" class="btn btn-custon-rounded-four btn-success">Atualizar</button>
                                                            :
                                                            <button onClick={() => cadastraCategoria()} style={{ height: 40 }} type="button" class="btn btn-custon-rounded-four btn-success">Cadastrar</button>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="asset-inner">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <th>Nome</th>
                                                            <th>Usuário</th>
                                                            <th>Data</th>
                                                            <th>Status</th>
                                                            <th>Ações</th>
                                                        </tr>
                                                        {categorias.map((categoria) =>
                                                            <tr key={categoria.id}>
                                                                <td>{categoria.nome}</td>
                                                                <td>{categoria.usuario.nome}</td>
                                                                <td>{moment(categoria.dataCadastro).format('DD/MM/YYYY - HH:mm')}</td>
                                                                <td>
                                                                    {categoria.status === 'ATIVO' ?
                                                                        <button type="button" class="btn btn-custon-four btn-success"><i class="fa fa-check edu-checked-pro" aria-hidden="true"></i></button>
                                                                        :
                                                                        <button type="button" class="btn btn-custon-four btn-danger"><i class="fa fa-close edu-checked-pro" aria-hidden="true"></i></button>
                                                                    }

                                                                </td>
                                                                <td>
                                                                    <button onClick={() => detalhe(categoria)} data-toggle="tooltip" title="Edit" className="pd-setting-ed"><i className="fa fa-pencil-square-o" aria-hidden="true" /></button>
                                                                    <button data-toggle="tooltip" title="Trash" className="pd-setting-ed"><i className="fa fa-trash-o" aria-hidden="true" /></button>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {categorias.length === 0 ?
                                                    <p style={{ textAlign: 'center', marginTop: 20 }}>Sem categorias cadastradas</p>
                                                    :
                                                    null
                                                }

                                            </div>
                                            {categorias.length > 10 ?
                                                <div className="custom-pagination">
                                                    <nav aria-label="Page navigation example">
                                                        <ul className="pagination">
                                                            <li className="page-item"><a className="page-link" href="#">Previous</a></li>
                                                            <li className="page-item"><a className="page-link" href="#">1</a></li>
                                                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                            <li className="page-item"><a className="page-link" href="#">Next</a></li>
                                                        </ul>
                                                    </nav>
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}