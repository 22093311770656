import React, { useEffect, useState, useRef } from 'react';
import firebase from 'firebase';
import Swal from 'sweetalert2'
import SunEditor from 'suneditor-react';

// Componentes
import Sidebar from '../../Componentes/Sidebar'
import Header from '../../Componentes/Header';
import moment from 'moment';

import 'suneditor/dist/css/suneditor.min.css';


export default function CadsatraAula(props) {
    const [modulo] = useState(props.location.state ? props.location.state.modulo : '')
    const [curso] = useState(props.location.state ? props.location.state.curso : '')
    const [aula] = useState(props.location.state && props.location.state.aula ? props.location.state.aula : '')

    const [servicos] = useState({
        auth: firebase.auth(),
        cursos: firebase.firestore().collection('cursos'),
        usuario: firebase.firestore().collection('usuarios'),
    })
    const [usuario, setUsuario] = useState({})
    const [optionsSun] = useState({
        mode: "classic",
        rtl: false,
        katex: "window.katex",
        videoFileInput: false,
        tabDisable: false,
        buttonList: [
            [
                "undo",
                "redo",
                "font",
                "fontSize",
                "formatBlock",
                "paragraphStyle",
                "blockquote",
                "bold",
                "underline",
                "italic",
                "strike",
                "subscript",
                "superscript",
                "fontColor",
                "hiliteColor",
                "textStyle",
                "removeFormat",
                "outdent",
                "indent",
                "align",
                "horizontalRule",
                "list",
                "lineHeight",
                "table",
                "link",
                "image",
                "video",
                "audio",
                "math",
                "imageGallery",
                "showBlocks",
                "codeView",
                "preview",
                "print",
                "save",
                "template"
            ]
        ],
    })

    // Post
    const [titulo, setTitulo] = useState(aula.id? aula.titulo:'')
    const [descricao, setDescricao] = useState(aula.id? aula.descricao:'')
    const [posicao, setPosicao] = useState(aula.id? aula.posicao:'')
    const [duracao, setDuracao] = useState(aula.id? aula.duracao:0)
    const [status, setStatus] = useState(aula.id? aula.status:'')
    const [url, setUrl] = useState(aula.id? aula.url:'')
    const [conteudo, setConteudo] = useState(aula.id? aula.conteudo:'')

    // const [titulo, setTitulo] = useState('')
    // const [descricao, setDescricao] = useState('')
    // const [posicao, setPosicao] = useState('')
    // const [duracao, setDuracao] = useState(0)
    // const [status, setStatus] = useState('')
    // const [url, setUrl] = useState('')
    // const [conteudo, setConteudo] = useState('')

    const [miniatura, setMiniatura] = useState('');
    const [upload, setUpload] = useState('');

    useEffect(() => {
        servicos.auth.onAuthStateChanged((user) => {
            if (user) {
                servicos.usuario.doc(user.uid).get().then((u) => {
                    setUsuario(u.data())
                })
            }
        })

    }, [])

    function cadastrar() {
        if (!titulo) {
            mensagemAlerta('Digite o Titulo')
            return
        }
        if (!descricao) {
            mensagemAlerta('Digite a Descrição')
            return
        }

        if (!conteudo) {
            mensagemAlerta('Digite o conteúdo')
            return
        }
        if (!status) {
            mensagemAlerta('Escolha o Status')
            return
        }
        if(aula.id){
            mensagemLoading('Atualizando Aula..')
            if(parseInt(aula.posicao) === parseInt(posicao)){
                servicos.cursos.doc(curso.id).collection('aulas').doc(aula.id).update({
                    posicao: parseInt(posicao),
                    titulo: titulo,
                    descricao: descricao,
                    conteudo: conteudo,
                    status: status,
                    url:url,
                    dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                }).then((snap) => {
                    if(upload){
                        cadastraVideo(aula.id)
                    }else{
                        mensagemSucesso('Aula atualizada com sucesso')
                    }
                })
            }else{
                servicos.cursos.doc(curso.id).collection('aulas').where('modulo','==',modulo.id).where('posicao','==',parseInt(posicao)).get().then((res)=>{
                    if(res.size > 0){
                        mensagemAlerta('Esta posição já está atrelada a outra aula')
                    }else{
                        servicos.cursos.doc(curso.id).collection('aulas').doc(aula.id).update({
                            posicao: parseInt(posicao),
                            titulo: titulo,
                            descricao: descricao,
                            conteudo: conteudo,
                            status: status,
                            url:url,
                            dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                        }).then((snap) => {
                            if(upload){
                                cadastraVideo(aula.id)
                            }else{
                                mensagemSucesso('Aula atualizada com sucesso')
                            }
                        })
                    }
                })
            }
            
        }else{
            mensagemLoading('Cadastrando Aula..')
            servicos.cursos.doc(curso.id).collection('aulas').where('modulo','==',modulo.id).where('posicao','==',parseInt(posicao)).get().then((res)=>{
                if(res.size > 0){
                    mensagemAlerta('Esta posição já está atrelada a outra aula')
                }else{
                    servicos.cursos.doc(curso.id).collection('aulas').add({
                        posicao: parseInt(posicao),
                        titulo: titulo,
                        descricao: descricao,
                        classificacao: parseInt(0),
                        alunos: parseInt(0),
                        duracao: parseFloat(duracao),
                        modulo:modulo.id,
                        url:url,
                        conteudo: conteudo,
                        status: status,
                        dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                        dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                    }).then((snap) => {
                        servicos.cursos.doc(curso.id).collection('aulas').doc(snap.id).update({
                            id: snap.id
                        }).then(() => {
                            if(upload){
                                cadastraVideo(snap.id)
                            }else{
                                mensagemSucesso('Aula cadastrada com sucesso')
                            }
                        })
                    })
                }
            })
        }
        
    }

    function cadastraVideo(id) {
        Swal.close();
        mensagemLoading('Enviando Video...')
        var uploadTask = firebase.storage().ref().child('aulas/' + id + '.' + miniatura[0].type.replace('image/', '')).put(miniatura[0], { contentType: miniatura[0].type });
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.cursos.doc(curso.id).collection('aulas').doc(id).update({
                    miniatura: downloadURL,
                    extensaoMiniatura:miniatura[0].type.replace('image/', '')
                }).then(() => {
                    mensagemSucesso('Aula cadastrada com sucesso')
                })
            });
        });
    }

    // Inputs
    const handlePosicao = (event) => {
        event.persist();
        setPosicao(event.target.value);
    }
    const handleMiniatura = (event) => {
        event.persist();
        setMiniatura(event.target.files)
        setUpload(URL.createObjectURL(event.target.files[0]))
    }
    const handleTitulo = (event) => {
        event.persist();
        setTitulo(event.target.value);
    }
    const handleDescricao = (event) => {
        event.persist();
        setDescricao(event.target.value);
    }
    const handleDuracao = (event) => {
        event.persist();
        setDuracao(event.target.value)
    }
    const handleUrl = (event) => {
        event.persist();
        setUrl(event.target.value)
    }
    const handleConteudo = (event) => {
        setConteudo(event);
    }
    const handleStatus = (event) => {
        event.persist();
        setStatus(event.target.value);
    }

    // Mensagens
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.history.back()
        })
    }
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="dashboard-main-wrapper">
            <Header />
            <Sidebar />
            <div className="main-panel">
                <div className="content">
                    <div className="page-inner">
                        <div className="page-header">
                            <h4 className="page-title">{aula && aula.id ? titulo : 'Nova Aula'}</h4>
                            <ul className="breadcrumbs">

                            </ul>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card card-profile card-secondary">
                                    <div className="card-header" style={{ backgroundImage: 'url("../assets/img/blogpost.jpg")' }}>
                                        <div className="profile-picture">
                                            <div className="avatar avatar-xl">
                                                {upload ?
                                                    <img src={upload} alt="..." className="avatar-img rounded-circle" />
                                                    : miniatura ?
                                                        <img src={miniatura} alt="..." className="avatar-img rounded-circle" />
                                                        :
                                                        <img src="https://www.kindpng.com/picc/m/10-109847_admin-icon-hd-png-download.png" alt="..." className="avatar-img rounded-circle" />
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="user-profile text-center">
                                            <div className="name">Miniatura</div>
                                            <div className="view-profile">
                                                <input onChange={handleMiniatura} type="file" className="form-control" id="email2" />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="col-md-8">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="card-title">Dados da Aula</div>
                                    </div>
                                    <div className="card-body row">
                                        <div className="form-group col-md-6">
                                            <label >Títutlo</label>
                                            <input onChange={handleTitulo} value={titulo} type="email" className="form-control" id="email2" />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label >Descrição</label>
                                            <input onChange={handleDescricao} value={descricao} type="email" className="form-control" id="email2" />
                                        </div>
                                        <div className="form-group col-md-2">
                                            <label >Posição</label>
                                            <input value={posicao} onChange={handlePosicao} type="number" className="form-control" id="email2" />
                                        </div>
                                        <div className="form-group col-md-2">
                                            <label >Duração(m)</label>
                                            <input value={duracao} onChange={handleDuracao} type="number" className="form-control" id="email2" />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label >Status</label>
                                            <select className="form-control" onChange={handleStatus}>
                                                <option>Selecione</option>
                                                <option value={'ATIVO'} selected={aula.id && status === 'ATIVO'?'selected':null}>Ativo</option>
                                                <option value={'INATIVO'} selected={aula.id && status === 'INATIVO'?'selected':null}>Inativo</option>
                                            </select>
                                        </div>
                                        <div className="form-group col-md-5">
                                            <label >ID URL</label>
                                            <input value={url} onChange={handleUrl} type="text" className="form-control" id="email2" />
                                        </div>
                                        <div className="form-group col-md-12">
                                            <SunEditor
                                                setOptions={optionsSun}
                                                lang="pt_br"
                                                placeholder="Conteúdo"
                                                onChange={handleConteudo}
                                                setContents={conteudo}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="card-action">
                                    <button onClick={() => cadastrar()} className="btn btn-success">Cadastrar</button>
                                    <button onClick={() => window.history.back()} style={{ marginLeft: 10 }} className="btn btn-danger">Cancelar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
}