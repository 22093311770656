import React, { useEffect, useState } from 'react';
import firebase from 'firebase';

export default function Sidebar() {
    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection('usuarios')
    })
    const [usuario, setUsuario] = useState({})
    useEffect(() => {
        servicos.auth.onAuthStateChanged((res) => {
            if (res) {
                servicos.usuarios.doc(res.uid).get().then((dados) => {
                    setUsuario(dados.data())
                })
            }
        })
    }, [])
    return (
        <div className="sidebar">
            <div className="sidebar-background" />
            <div className="sidebar-wrapper scrollbar-inner">
                <div className="sidebar-content">
                    <div className="user">
                        <div className="avatar-sm float-left mr-2">
                            <img src={usuario.avatar ? usuario.avatar : "https://www.kindpng.com/picc/m/10-109847_admin-icon-hd-png-download.png"} alt="..." className="avatar-img rounded-circle" />
                        </div>
                        {usuario.id ?
                            <div className="info">
                                <a data-toggle="collapse" href="#collapseExample" aria-expanded="true">
                                    <span>
                                        {usuario.username}
                                        <span className="user-level">{usuario.nome}</span>
                                        <span className="user-level">{usuario.usuario}</span>
                                    </span>
                                </a>
                                <div className="clearfix" />
                            </div>
                            :
                            null
                        }

                    </div>
                    {usuario.usuario === 'ADMINISTRADOR' ?
                        <ul className="nav">
                            <li className="nav-item active">
                                <a href="/administrador">
                                    <i className="fas fa-home" />
                                    <p>Dashboard</p>
                                </a>
                            </li>
                            <li className="nav-section">
                                <span className="sidebar-mini-icon">
                                    <i className="fa fa-ellipsis-h" />
                                </span>
                                <h4 className="text-section">O Fábrica</h4>
                            </li>
                            <li className="nav-item">
                                <a  href="/projetos">
                                    <i className="fas fa-handshake" />
                                    <p>Projetos</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a data-toggle="collapse" href="#usuarios">
                                    <i className="fas fa-donate" />
                                    <p>Doações</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a data-toggle="collapse" href="#usuarios">
                                    <i className="fas fa-award" />
                                    <p>Premiações</p>
                                </a>
                            </li>
                            <li className="nav-section">
                                <span className="sidebar-mini-icon">
                                    <i className="fa fa-ellipsis-h" />
                                </span>
                                <h4 className="text-section">Administrativo</h4>
                            </li>
                            <li className="nav-item">
                                <a data-toggle="collapse" href="#cursos">
                                    <i className="fas fa-film" />
                                    <p>Cursos</p>
                                    <span className="caret" />
                                </a>
                                <div className="collapse" id="cursos">
                                    <ul className="nav nav-collapse">
                                        <li>
                                            <a href="/categoriasCursos">
                                                <span className="sub-item">Categorias</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/cursos">
                                                <span className="sub-item">Cursos</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <a data-toggle="collapse" href="#financeiro">
                                    <i className="fas fa-money-check-alt" />
                                    <p>Financeiro</p>
                                    <span className="caret" />
                                </a>
                                <div className="collapse" id="financeiro">
                                    <ul className="nav nav-collapse">
                                        <li>
                                            <a href="/financeiro">
                                                <span className="sub-item">Relatório Geral</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/professoras">
                                                <span className="sub-item">Por Professores</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/escolas">
                                                <span className="sub-item">Contas a pagar</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/escolas">
                                                <span className="sub-item">Contas a Receber</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <a data-toggle="collapse" href="#usuarios">
                                    <i className="fas fa-users" />
                                    <p>Usuários</p>
                                    <span className="caret" />
                                </a>
                                <div className="collapse" id="usuarios">
                                    <ul className="nav nav-collapse">
                                        <li>
                                            <a href="/usuarios">
                                                <span className="sub-item">Administradores</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/professores">
                                                <span className="sub-item">Professores</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/alunos">
                                                <span className="sub-item">Alunos</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/parceiros">
                                                <span className="sub-item">Parceiros</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <a data-toggle="collapse" href="#marketing">
                                    <i className="fab fa-markdown" />
                                    <p>Marketing</p>
                                    <span className="caret" />
                                </a>
                                <div className="collapse" id="marketing">
                                    <ul className="nav nav-collapse">
                                        <li>
                                            <a href="/usuarios">
                                                <span className="sub-item">Slide</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <a data-toggle="collapse" href="#financeiro">
                                    <i className="fas fa-headset" />
                                    <p>Suporte</p>
                                </a>
                            </li>
                        </ul>
                        :
                        <ul className="nav">
                            <li className="nav-item active">
                                <a href="/professor">
                                    <i className="fas fa-home" />
                                    <p>Dashboard</p>
                                </a>
                            </li>
                            <li className="nav-section">
                                <span className="sidebar-mini-icon">
                                    <i className="fa fa-ellipsis-h" />
                                </span>
                                <h4 className="text-section">Administrativo</h4>
                            </li>
                            <li className="nav-item">
                                <a  href="/cursos">
                                    <i className="fas fa-film" />
                                    <p>Cursos</p>
                                </a>
                                <a data-toggle="collapse" href="#financeiro">
                                    <i className="fas fa-money-check-alt" />
                                    <p>Financeiro</p>
                                </a>
                                <a data-toggle="collapse" href="#financeiro">
                                    <i className="fas fa-headset" />
                                    <p>Suporte</p>
                                </a>
                                <a data-toggle="collapse" href="#financeiro">
                                    <i className="fas fa-cog" />
                                    <p>Configurações</p>
                                </a>
                            </li>
                        </ul>
                    }


                </div>
            </div>
        </div>

    );
}