import React, { useEffect, useState } from 'react';
import firebase from 'firebase';
import Swal from 'sweetalert2'

// Componentes
import Sidebar from '../../Componentes/Sidebar'
import Header from '../../Componentes/Header';
import moment from 'moment';

export default function CadsatroProjeto() {
    const [servicos] = useState({
        auth: firebase.auth(),
        projetos: firebase.firestore().collection('projetos'),
        usuario: firebase.firestore().collection('usuarios'),
    })
    const [usuario, setUsuario] = useState({})
    const [projeto, setProjeto] = useState({})

    // Dados
    const [titulo, setTitulo] = useState('')
    const [descricao, setDescricao] = useState('')
    const [caracteristica1, setCaracteristica1] = useState('')
    const [caracteristica2, setCaracteristica2] = useState('')
    const [caracteristica3, setCaracteristica3] = useState('')
    const [caracteristica4, setCaracteristica4] = useState('')
    const [caracteristica5, setCaracteristica5] = useState('')
    const [caracteristica6, setCaracteristica6] = useState('')
    const [conteudo, setConteudo] = useState('')
    const [frase, setFrase] = useState('')
    const [status, setStatus] = useState('')

    // Localidade e Contato
    const [responsavel, setResponsavel] = useState('')
    const [contato, setContato] = useState('')
    const [email, setEmail] = useState('')
    const [cep, setCep] = useState('')
    const [endereco, setEndereco] = useState('')
    const [bairro, setBairro] = useState('')
    const [numero, setNumero] = useState('')
    const [complemento, setComplemento] = useState('')
    const [uf, setUf] = useState('')
    const [cidade, setCidade] = useState('')
    const [referencia, setReferencia] = useState('')

    // Contas e Tipo
    const [tipo, setTipo] = useState('')
    const [tipoPix, setTipoPix] = useState('')
    const [chavePix, setChavePix] = useState('')
    const [linkPagamento, setLinkPagamento] = useState('')
    const [favorecido, setFavorecido] = useState('')

    var metadata = { contentType: 'image/jpeg' };
    const [miniatura, setMiniatura] = useState('');
    const [uploadMiniatura, setUploadMiniatura] = useState('');
    const [imagem, setImagem] = useState('');
    const [uploadImagem, setUploadImagem] = useState('');

    useEffect(() => {

        if (window.location.search.replace('?', '')) {
            servicos.projetos.doc(window.location.search.replace('?', '')).get().then((b) => {
                setProjeto(b.data())
                setMiniatura(b.data().miniatura)
                setImagem(b.data().imagem)
                setTitulo(b.data().titulo)
                setDescricao(b.data().descricao)
                setCaracteristica1(b.data().caracteristica1)
                setCaracteristica2(b.data().caracteristica2)
                setCaracteristica3(b.data().caracteristica3)
                setCaracteristica4(b.data().caracteristica4)
                setCaracteristica5(b.data().caracteristica5)
                setCaracteristica6(b.data().caracteristica6)
                setConteudo(b.data().conteudo)
                setFrase(b.data().frase)

                setResponsavel(b.data().responsavel)
                setContato(b.data().contato)
                setEmail(b.data().email)
                setCep(b.data().cep)
                setEndereco(b.data().endereco)
                setBairro(b.data().bairro)
                setNumero(b.data().numero)
                setComplemento(b.data().complemento)
                setNumero(b.data().numero)
                setComplemento(b.data().complemento)
                setUf(b.data().uf)
                setCidade(b.data().cidade)
                setReferencia(b.data().referencia)

                setTipo(b.data().tipo)
                setTipoPix(b.data().tipoPix)
                setChavePix(b.data().chavePix)
                setLinkPagamento(b.data().linkPagamento)
                setFavorecido(b.data().favorecido)

                setStatus(b.data().status)
            })
        }
        servicos.auth.onAuthStateChanged((user) => {
            if (user) {
                servicos.usuario.doc(user.uid).get().then((u) => {
                    setUsuario(u.data())
                })
            }
        })

    }, [])

    function cadastrar() {
        if (!miniatura) {
            mensagemAlerta('Escolha a imagem da miniatura')
            return
        }
        if (!imagem) {
            mensagemAlerta('Escolha a imagem Principal')
            return
        }
        if (!titulo) {
            mensagemAlerta('Digite o Titulo')
            return
        }
        if (!descricao) {
            mensagemAlerta('Digite a Descrição')
            return
        }

        if (!conteudo) {
            mensagemAlerta('Digite o conteúdo')
            return
        }
        if (!frase) {
            mensagemAlerta('Digite a Frase')
            return
        }
        if (!responsavel) {
            mensagemAlerta('Digite o nome do Responsavel')
            return
        }
        if (!contato || contato.length < 11) {
            mensagemAlerta('Digite numero para contato')
            return
        }
        if (!email || !email.includes('@')) {
            mensagemAlerta('Digite o email')
            return
        }
        if (!tipo) {
            mensagemAlerta('Escolha o tipo')
            return
        }
        if (!tipoPix) {
            mensagemAlerta('Escolha o tipo PIX')
            return
        }
        if (!chavePix) {
            mensagemAlerta('Digite a Chave PIX')
            return
        }
        if (!linkPagamento) {
            mensagemAlerta('Digite o Link de Pagamento')
            return
        }
        if (!favorecido) {
            mensagemAlerta('Digite o nome do Favorecido')
            return
        }
        if (!status) {
            mensagemAlerta('Escolha o Status')
            return
        }
        if (projeto.id) {
            mensagemLoading('Atualizando Projeto..')
            servicos.projetos.doc(projeto.id).update({
                titulo: titulo,
                descricao: descricao,
                caracteristica1: caracteristica1,
                caracteristica2: caracteristica2,
                caracteristica3: caracteristica3,
                caracteristica4: caracteristica4,
                caracteristica5: caracteristica5,
                caracteristica6: caracteristica6,
                conteudo: conteudo,
                frase:frase,
                responsavel:responsavel,
                contato:contato,
                email:email,
                cep:cep,
                endereco:endereco,
                bairro:bairro,
                numero:numero,
                complemento:complemento,
                uf:uf,
                cidade:cidade,
                referencia:referencia,
                tipo:tipo,
                tipoPix:tipoPix,
                chavePix:chavePix,
                linkPagamento:linkPagamento,
                favorecido:favorecido,
                status: status,
                usuario: {
                    nome: usuario.nome,
                    id: usuario.id,
                    email: usuario.email
                },
                dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
            }).then((snap) => {
                if (uploadMiniatura) {
                    cadastraMiniatua(projeto.id)
                } else if (uploadImagem) {
                    cadastraImagem(projeto.id)
                } else {
                    mensagemSucesso('Projeto atualizado com sucesso!')
                }
            })
        } else {
            mensagemLoading('Cadastrando Projeto..')
            servicos.projetos.add({
                titulo: titulo,
                descricao: descricao,
                caracteristica1: caracteristica1,
                caracteristica2: caracteristica2,
                caracteristica3: caracteristica3,
                caracteristica4: caracteristica4,
                caracteristica5: caracteristica5,
                caracteristica6: caracteristica6,
                conteudo: conteudo,
                frase:frase,
                responsavel:responsavel,
                contato:contato,
                email:email,
                cep:cep,
                endereco:endereco,
                bairro:bairro,
                numero:numero,
                complemento:complemento,
                uf:uf,
                cidade:cidade,
                referencia:referencia,
                tipo:tipo,
                tipoPix:tipoPix,
                chavePix:chavePix,
                linkPagamento:linkPagamento,
                favorecido:favorecido,
                status: status,
                usuario: {
                    nome: usuario.nome,
                    id: usuario.id,
                    email: usuario.email
                },
                dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
            }).then((snap) => {
                servicos.projetos.doc(snap.id).update({
                    id: snap.id
                }).then(() => {
                    cadastraMiniatua(snap.id)
                })
            })
        }


    }

    function cadastraMiniatua(id) {
        Swal.close();
        mensagemLoading('Enviando a miniatura...')
        var uploadTask = firebase.storage().ref().child('projetos/' + id + '/miniatura.' + miniatura[0].type.replace('image/', '')).put(miniatura[0], { contentType: miniatura[0].type });
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.projetos.doc(id).update({
                    miniatura: downloadURL
                }).then(() => {
                    if (uploadImagem) {
                        cadastraImagem(id)
                    } else {
                        mensagemSucesso('Miniatura atualizada com sucesso')
                    }

                })
            });
        });
    }

    function cadastraImagem(id) {
        Swal.close();
        mensagemLoading('Enviando a imagem principal...')
        var uploadTask = firebase.storage().ref().child('projetos/' + id + '/imagem.' + imagem[0].type.replace('image/', '')).put(imagem[0], { contentType: imagem[0].type });
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.projetos.doc(id).update({
                    imagem: downloadURL
                }).then(() => {
                    mensagemSucesso('Projeto cadastrado com sucesso')
                })
            });
        });
    }

    // Dados
    const handleMiniatura = (event) => {
        event.persist();
        setMiniatura(event.target.files)
        console.log(event.target.files[0].type.replace('image/', ''))
        setUploadMiniatura(URL.createObjectURL(event.target.files[0]))
    }
    const handleImagem = (event) => {
        event.persist();
        setImagem(event.target.files)
        setUploadImagem(URL.createObjectURL(event.target.files[0]))
    }
    const handleTitulo = (event) => {
        event.persist();
        setTitulo(event.target.value);
    }
    const handleDescricao = (event) => {
        event.persist();
        setDescricao(event.target.value);
    }
    const handleCaracteristica1 = (event) => {
        event.persist();
        setCaracteristica1(event.target.value);
    }
    const handleCaracteristica2 = (event) => {
        event.persist();
        setCaracteristica2(event.target.value);
    }
    const handleCaracteristica3 = (event) => {
        event.persist();
        setCaracteristica3(event.target.value);
    }
    const handleCaracteristica4 = (event) => {
        event.persist();
        setCaracteristica4(event.target.value);
    }
    const handleCaracteristica5 = (event) => {
        event.persist();
        setCaracteristica5(event.target.value);
    }
    const handleCaracteristica6 = (event) => {
        event.persist();
        setCaracteristica6(event.target.value);
    }
    const handleConteudo = (event) => {
        event.persist();
        setConteudo(event.target.value);
    }
    const handleFrase = (event) => {
        event.persist();
        setFrase(event.target.value);
    }
    const handleStatus = (event) => {
        event.persist();
        setStatus(event.target.value);
    }

    // Localidades e Contatos
    const handleResponsavel = (event) => {
        event.persist();
        setResponsavel(event.target.value);
    }
    const handleContato = (event) => {
        event.persist();
        setContato(event.target.value);
    }
    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }
    const handleCep = (event) => {
        event.persist();
        setCep(event.target.value);
    }
    const handleEndereco = (event) => {
        event.persist();
        setEndereco(event.target.value);
    }
    const handleBairro = (event) => {
        event.persist();
        setBairro(event.target.value);
    }
    const handleNumero = (event) => {
        event.persist();
        setNumero(event.target.value);
    }
    const handleComplemento = (event) => {
        event.persist();
        setComplemento(event.target.value);
    }
    const handleUf = (event) => {
        event.persist();
        setUf(event.target.value);
    }
    const handleCidade = (event) => {
        event.persist();
        setCidade(event.target.value);
    }
    const handleReferencia = (event) => {
        event.persist();
        setReferencia(event.target.value);
    }

    // Contas e Tipos
    const handleTipo = (event) => {
        event.persist();
        setTipo(event.target.value);
    }
    const handleTipoPix = (event) => {
        event.persist();
        setTipoPix(event.target.value);
    }
    const handleChavePix = (event) => {
        event.persist();
        setChavePix(event.target.value);
    }
    const handleLinkPagamento = (event) => {
        event.persist();
        setLinkPagamento(event.target.value);
    }
    const handleFavorecido = (event) => {
        event.persist();
        setFavorecido(event.target.value);
    }

    // Mensagens
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.history.back()
        })
    }
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <>
            {/* <Sidebar /> */}
            <div className="all-content-wrapper">
                <Header />
                <div className="analytics-sparkle-area">
                    <div className="container-fluid">
                        <div className="breadcome-list" style={{ marginTop: 80 }}>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12">
                                    <h1>Novo Projeto</h1>
                                </div>
                            </div>
                        </div>
                        <div className="single-pro-review-area mt-t-30 mg-b-15">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="product-payment-inner-st">
                                            <div>
                                                <h5>Imagens do Projeto</h5>
                                            </div>
                                            <div className="row" style={{ marginTop: 20 }}>
                                                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                                    <div class="profile-img">
                                                        {uploadMiniatura ?
                                                            <img src={uploadMiniatura} alt="" />
                                                            : miniatura ?
                                                                <img src={miniatura} alt="" />
                                                                :
                                                                <img src="https://cdn-icons-png.flaticon.com/512/3342/3342176.png" alt="" />
                                                        }

                                                        <p style={{ marginTop: 20 }}>Imagem de miniatura</p>
                                                        <input accept='.png,.jpg,.jpeg' onChange={handleMiniatura} type="file" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-8 col-md-6 col-sm-6 col-xs-12">
                                                    <div class="profile-img">
                                                        {uploadImagem ?
                                                            <img src={uploadImagem} alt="" />
                                                            : imagem ?
                                                                <img src={imagem} alt="" />
                                                                :
                                                                <img src="https://i.pinimg.com/736x/ec/14/7c/ec147c4c53abfe86df2bc7e70c0181ff.jpg" alt="" />
                                                        }

                                                        <p style={{ marginTop: 20 }}>Imagem Principal</p>
                                                        <input accept='.png,.jpg,.jpeg' onChange={handleImagem} type="file" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="single-pro-review-area mt-t-30 mg-b-15">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="product-payment-inner-st">
                                            <div>
                                                <h5>Dados do Projeto</h5>
                                            </div>
                                            <div className="row" style={{ marginTop: 20 }}>

                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                        <input value={titulo} onChange={handleTitulo}  type="text" className="form-control" placeholder="Título" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                        <input value={descricao} onChange={handleDescricao}  type="text" className="form-control" placeholder="Descrição" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                        <input value={caracteristica1} onChange={handleCaracteristica1}  type="text" className="form-control" placeholder="caracteristica 1" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                        <input value={caracteristica2} onChange={handleCaracteristica2}  type="text" className="form-control" placeholder="caracteristica 2" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                        <input value={caracteristica3} onChange={handleCaracteristica3}  type="text" className="form-control" placeholder="caracteristica 3" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                        <input value={caracteristica4} onChange={handleCaracteristica4}  type="text" className="form-control" placeholder="caracteristica 4" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                        <input value={caracteristica5} onChange={handleCaracteristica5}  type="text" className="form-control" placeholder="caracteristica 5" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                        <input value={caracteristica6} onChange={handleCaracteristica6}  type="text" className="form-control" placeholder="caracteristica 6" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-6 col-sm-6 col-xs-12">
                                                    <div class="form-group res-mg-t-15">
                                                        <textarea value={conteudo} onChange={handleConteudo} placeholder="Descreva o Projeto"></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-6 col-sm-6 col-xs-12">
                                                    <div class="form-group res-mg-t-15">
                                                        <textarea value={frase} onChange={handleFrase} placeholder="Descreva a frase de efeito"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="single-pro-review-area mt-t-30 mg-b-15">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="product-payment-inner-st">
                                            <div>
                                                <h5>Localidade e Contatos</h5>
                                            </div>
                                            <div className="row" style={{ marginTop: 20 }}>

                                                <div className="col-lg-5 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                        <input value={responsavel} onChange={handleResponsavel}  type="text" className="form-control" placeholder="Responsável" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                        <input value={contato} onChange={handleContato}  type="text" className="form-control" placeholder="Contato" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-5 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                        <input value={email} onChange={handleEmail}  type="email" className="form-control" placeholder="Email" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                        <input value={cep} onChange={handleCep}  type="text" className="form-control" placeholder="CEP" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                        <input value={endereco} onChange={handleEndereco}  type="text" className="form-control" placeholder="Endereço" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                        <input value={bairro} onChange={handleBairro}  type="text" className="form-control" placeholder="Bairro" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-1 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                        <input value={numero} onChange={handleNumero}  type="text" className="form-control" placeholder="N" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                        <input value={complemento} onChange={handleComplemento}  type="text" className="form-control" placeholder="Complemento" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                        <select name="gender" className="form-control" onChange={handleUf}>
                                                            <option value="">UF</option>
                                                            <option value="ATIVO" selected={projeto.id && projeto.status === 'ATIVO' ? 'selected' : null}>Ativo</option>
                                                            <option value="INATIVO" selected={projeto.id && projeto.status === 'INATIVO' ? 'selected' : null}>Inativo</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                        <select name="gender" className="form-control" onChange={handleCidade}>
                                                            <option value="">Cidade</option>
                                                            <option value="ATIVO" selected={projeto.id && projeto.status === 'ATIVO' ? 'selected' : null}>Ativo</option>
                                                            <option value="INATIVO" selected={projeto.id && projeto.status === 'INATIVO' ? 'selected' : null}>Inativo</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                        <input value={referencia} onChange={handleReferencia}  type="text" className="form-control" placeholder="Referencia" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="single-pro-review-area mt-t-30 mg-b-15">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="product-payment-inner-st">
                                            <div>
                                                <h5>Contas e Tipos</h5>
                                            </div>
                                            <div className="row" style={{ marginTop: 20 }}>

                                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                        <select name="gender" className="form-control" onChange={handleTipo}>
                                                            <option value="">Tipo</option>
                                                            <option value="ONLINE" selected={projeto.id && projeto.tipo === 'ONLINE' ? 'selected' : null}>Online</option>
                                                            <option value="PRESENCIAL" selected={projeto.id && projeto.tipo === 'PRESENCIAL' ? 'selected' : null}>Presencial</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                        <select name="gender" className="form-control" onChange={handleTipoPix}>
                                                            <option value="">Tipo PIX</option>
                                                            <option value="CPF" selected={projeto.id && projeto.tipoPix === 'CPF' ? 'selected' : null}>CPF</option>
                                                            <option value="CNPJ" selected={projeto.id && projeto.tipoPix === 'CNPJ' ? 'selected' : null}>CNPJ</option>
                                                            <option value="EMAIL" selected={projeto.id && projeto.tipoPix === 'EMAIL' ? 'selected' : null}>Email</option>
                                                            <option value="CELULAR" selected={projeto.id && projeto.tipoPix === 'CELULAR' ? 'selected' : null}>Celular</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                        <input value={chavePix} onChange={handleChavePix}  type="text" className="form-control" placeholder="Chave PIX" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                        <input value={linkPagamento} onChange={handleLinkPagamento}  type="email" className="form-control" placeholder="Link Pagamento" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-5 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                        <input value={favorecido} onChange={handleFavorecido}  type="email" className="form-control" placeholder="Nome Favorecido" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-6 col-sm-6 col-xs-12" />
                                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                        <select name="gender" className="form-control" onChange={handleStatus}>
                                                            <option value="">Status</option>
                                                            <option value="ATIVO" selected={projeto.id && projeto.status === 'ATIVO' ? 'selected' : null}>Ativo</option>
                                                            <option value="INATIVO" selected={projeto.id && projeto.status === 'INATIVO' ? 'selected' : null}>Inativo</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                
                                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                                    {projeto && projeto.id ?
                                                        <button onClick={() => cadastrar()} style={{ height: 40 }} type="submit" class="btn btn-primary waves-effect waves-light">Atualizar</button>
                                                        :
                                                        <button onClick={() => cadastrar()} style={{ height: 40 }} type="submit" class="btn btn-primary waves-effect waves-light">Cadastrar</button>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}