import React, { useEffect, useState } from 'react';

// Componentes
// import Sidebar from '../../../Componentes/Sidebar'
// import Footer from '../../../Componentes/Footer';
// import Header from '../../../Componentes/Header';
import firebase from 'firebase';

export default function Aluno() {
    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection('usuarios')
    })
    const [usuario, setUsuario] = useState({})
    const [cursos, setCursos] = useState([])
    useEffect(() => {
        servicos.auth.onAuthStateChanged((u) => {
            if (u) {
                servicos.usuarios.doc(u.uid).get().then((user) => {
                    setUsuario(user.data())
                    servicos.usuarios.doc(user.data().id).collection('cursos').get().then((d) => {
                        const data = d.docs.map((d) => d.data())
                        setCursos(data)
                    })
                })
            }
        })
    }, [usuario])
    return (
        <>
            {/* <Sidebar /> */}
            <div className="all-content-wrapper">
                {/* <Header /> */}
                <div className="analytics-sparkle-area">
                    <div className="container-fluid">
                        <div className="breadcome-list" style={{ marginTop: 80 }}>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12">
                                    <h1>{usuario.id ? usuario.nome : '...'}, </h1>
                                    <p>Bem vindo</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="traffic-analysis-area">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <div className="social-media-edu">
                                    <i className="fa fa-facebook" />
                                    <div className="social-edu-ctn">
                                        <h3>50k Likes</h3>
                                        <p>You main list growing</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <div className="social-media-edu twitter-cl res-mg-t-30 table-mg-t-pro-n">
                                    <i className="fa fa-twitter" />
                                    <div className="social-edu-ctn">
                                        <h3>30k followers</h3>
                                        <p>You main list growing</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <div className="social-media-edu linkedin-cl res-mg-t-30 res-tablet-mg-t-30 dk-res-t-pro-30">
                                    <i className="fa fa-linkedin" />
                                    <div className="social-edu-ctn">
                                        <h3>7k Connections</h3>
                                        <p>You main list growing</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <div className="social-media-edu youtube-cl res-mg-t-30 res-tablet-mg-t-30 dk-res-t-pro-30">
                                    <i className="fa fa-youtube" />
                                    <div className="social-edu-ctn">
                                        <h3>50k Subscribers</h3>
                                        <p>You main list growing</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="library-book-area mg-t-30">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                <div className="single-cards-item">
                                    <div className="single-product-image">
                                        <a href="#"><img src="img/product/profile-bg.jpg" /></a>
                                    </div>
                                    <div className="single-product-text">
                                        <img src={usuario.avatar ? usuario.avatar : "img/product/pro4.jpg"} />
                                        <h4><a className="cards-hd-dn" href="#">{usuario.nome}</a></h4>
                                        <h5>{usuario.email}</h5>
                                        <p className="ctn-cards">{usuario.descricao ? usuario.descricao : 'Escreva sobre você'}</p>
                                        <a className="follow-cards" href="/perfil">Editar</a>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                <div className="cards-dtn">
                                                    <h3><span className="counter">{cursos.length}</span></h3>
                                                    <p>Cursos</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                <div className="cards-dtn">
                                                    <h3><span className="counter">{usuario.like ? usuario.like : 0}</span></h3>
                                                    <p>Curtidas</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                <div className="cards-dtn">
                                                    <h3><span className="counter">10</span></h3>
                                                    <p>Ranking</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                <div className="single-review-st-item res-mg-t-30 table-mg-t-pro-n">
                                    <div className="single-review-st-hd">
                                        <h2>Top 5 Alunos</h2>
                                        <p>Veja abaixo a lista de Top 5 alunos da Fábrica de Sonhos Virtuais</p>
                                    </div>
                                    <div className="single-review-st-text">
                                        <img src="img/notification/1.jpg" />
                                        <div className="review-ctn-hf">
                                            <h3>Sarah Graves</h3>
                                            <p>Highly recommend</p>
                                        </div>
                                        <div className="review-item-rating">
                                            <i className="educate-icon educate-star" />
                                            <i className="educate-icon educate-star" />
                                            <i className="educate-icon educate-star" />
                                            <i className="educate-icon educate-star" />
                                            <i className="educate-icon educate-star-half" />
                                        </div>
                                    </div>
                                    <div className="single-review-st-text">
                                        <img src="img/notification/2.jpg" />
                                        <div className="review-ctn-hf">
                                            <h3>Garbease sha</h3>
                                            <p>Awesome Pro</p>
                                        </div>
                                        <div className="review-item-rating">
                                            <i className="educate-icon educate-star" />
                                            <i className="educate-icon educate-star" />
                                            <i className="educate-icon educate-star" />
                                            <i className="educate-icon educate-star" />
                                            <i className="educate-icon educate-star-half" />
                                        </div>
                                    </div>
                                    <div className="single-review-st-text">
                                        <img src="img/notification/3.jpg" />
                                        <div className="review-ctn-hf">
                                            <h3>Gobetro pro</h3>
                                            <p>Great Website</p>
                                        </div>
                                        <div className="review-item-rating">
                                            <i className="educate-icon educate-star" />
                                            <i className="educate-icon educate-star" />
                                            <i className="educate-icon educate-star" />
                                            <i className="educate-icon educate-star" />
                                            <i className="educate-icon educate-star-half" />
                                        </div>
                                    </div>
                                    <div className="single-review-st-text">
                                        <img src="img/notification/4.jpg" />
                                        <div className="review-ctn-hf">
                                            <h3>Siam Graves</h3>
                                            <p>That's Good</p>
                                        </div>
                                        <div className="review-item-rating">
                                            <i className="educate-icon educate-star" />
                                            <i className="educate-icon educate-star" />
                                            <i className="educate-icon educate-star" />
                                            <i className="educate-icon educate-star" />
                                            <i className="educate-icon educate-star-half" />
                                        </div>
                                    </div>
                                    <div className="single-review-st-text">
                                        <img src="img/notification/5.jpg" />
                                        <div className="review-ctn-hf">
                                            <h3>Sarah Graves</h3>
                                            <p>Highly recommend</p>
                                        </div>
                                        <div className="review-item-rating">
                                            <i className="educate-icon educate-star" />
                                            <i className="educate-icon educate-star" />
                                            <i className="educate-icon educate-star" />
                                            <i className="educate-icon educate-star" />
                                            <i className="educate-icon educate-star-half" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                <div className="white-box res-mg-t-30 table-mg-t-pro-n">
                                    <h3 className="box-title">Cursos que você pode gostar</h3>
                                    <ul className="country-state">
                                        <li>
                                            <h2><span className="counter">1250</span></h2> <small>From Australia</small>
                                            <div className="pull-right">75% <i className="fa fa-level-up text-danger ctn-ic-1" />
                                            </div>
                                            <div className="progress">
                                                <div className="progress-bar progress-bar-danger ctn-vs-1" role="progressbar" aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} style={{ width: '75%' }}>
                                                    <span className="sr-only">75% Complete</span></div>
                                            </div>
                                        </li>
                                        <li>
                                            <h2><span className="counter">1050</span></h2> <small>From USA</small>
                                            <div className="pull-right">48% <i className="fa fa-level-up text-success ctn-ic-2" />
                                            </div>
                                            <div className="progress">
                                                <div className="progress-bar progress-bar-info ctn-vs-2" role="progressbar" aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} style={{ width: '48%' }}>
                                                    <span className="sr-only">48% Complete</span></div>
                                            </div>
                                        </li>
                                        <li>
                                            <h2><span className="counter">6350</span></h2> <small>From Canada</small>
                                            <div className="pull-right">55% <i className="fa fa-level-up text-success ctn-ic-3" />
                                            </div>
                                            <div className="progress">
                                                <div className="progress-bar progress-bar-success ctn-vs-3" role="progressbar" aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} style={{ width: '55%' }}>
                                                    <span className="sr-only">55% Complete</span></div>
                                            </div>
                                        </li>
                                        <li>
                                            <h2><span className="counter">950</span></h2> <small>From India</small>
                                            <div className="pull-right">33% <i className="fa fa-level-down text-success ctn-ic-4" />
                                            </div>
                                            <div className="progress">
                                                <div className="progress-bar progress-bar-success ctn-vs-4" role="progressbar" aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} style={{ width: '33%' }}>
                                                    <span className="sr-only">33% Complete</span></div>
                                            </div>
                                        </li>
                                        <li>
                                            <h2><span className="counter">3250</span></h2> <small>From Bangladesh</small>
                                            <div className="pull-right">60% <i className="fa fa-level-up text-success ctn-ic-5" />
                                            </div>
                                            <div className="progress">
                                                <div className="progress-bar progress-bar-inverse ctn-vs-5" role="progressbar" aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} style={{ width: '60%' }}>
                                                    <span className="sr-only">60% Complete</span></div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                {/* <Footer /> */}
            </div>

        </>
    );
}