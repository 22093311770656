import React, { useEffect, useState } from 'react';
import firebase from 'firebase';
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom';

// Componentes
import Sidebar from '../../Componentes/Sidebar'
import Header from '../../Componentes/Header';
import moment from 'moment';

export default function Aulas(props) {
    const [modulo] = useState(props.location.state ? props.location.state.modulo : '')
    const [curso] = useState(props.location.state ? props.location.state.curso : '')
    const [servicos] = useState({
        auth: firebase.auth(),
        cursos: firebase.firestore().collection('cursos'),
    })
    // modulos
    const [posicaoModulo, setPosicaoModulo] = useState('')
    const [tituloModulo, setTituloModulo] = useState('')
    const [descricaoModulo, setDescricaoModulo] = useState('')

    // Aulas
    const [aulas, setAulas] = useState([])
    const [aula, setAula] = useState({})

    useEffect(() => {
        servicos.cursos.doc(curso.id).collection('aulas').where('modulo', '==', modulo.id).onSnapshot((snap) => {
            const data = snap.docs.map((d) => d.data())
            setAulas(data)
            Swal.close()
        })
    }, [])


    // Mensagens
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success')
    }
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="dashboard-main-wrapper">
            <Header />
            <Sidebar />
            <div className="main-panel">
                <div className="content">
                    <div className="page-inner">
                        <div className="page-header">
                            <h4 className="page-title">Aulas do módulo - {modulo.titulo}</h4>
                            <div className="btn-group btn-group-page-header ml-auto">
                                <button type="button" className="btn btn-light btn-round btn-page-header-dropdown dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-ellipsis-h" />
                                </button>
                                <div className="dropdown-menu">
                                    <div className="arrow" />
                                    <Link to={{ pathname: '/cadastraAula', state: { curso: curso, modulo: modulo, aula: {} } }} className="dropdown-item" href="/cadastraAula">Nova Aula</Link>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 col-md-6">
                                <div className="card card-stats card-round">
                                    <div className="card-body ">
                                        <div className="row align-items-center">
                                            <div className="col-icon">
                                                <div className="icon-big text-center icon-success bubble-shadow-small">
                                                    <i className="fas fa-check" />
                                                </div>
                                            </div>
                                            <div className="col col-stats ml-3 ml-sm-0">
                                                <div className="numbers">
                                                    <p className="card-category">Ativos</p>
                                                    <h4 className="card-title">{aulas.filter((d) => d.status === 'ATIVO').length}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6">
                                <div className="card card-stats card-round">
                                    <div className="card-body">
                                        <div className="row align-items-center">
                                            <div className="col-icon">
                                                <div className="icon-big text-center icon-danger bubble-shadow-small">
                                                    <i className="far fa-window-close" />
                                                </div>
                                            </div>
                                            <div className="col col-stats ml-3 ml-sm-0">
                                                <div className="numbers">
                                                    <p className="card-category">Inativos</p>
                                                    <h4 className="card-title">{aulas.filter((d) => d.status === 'INATIVO').length}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div class="card-header">
                                            <div class="card-title">Lista de Aulas</div>
                                        </div>
                                        <div className="card-sub">
                                            Aqui estão listadas todas as aulas do módulo {modulo.titulo}<br /><b>{curso.titulo}</b>                                        </div>
                                        <table className="table mt-3">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Posição</th>
                                                    <th scope="col">Avatar</th>
                                                    <th scope="col">Curso</th>
                                                    <th scope="col">Descrição</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Detalhe</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {aulas.sort((a, b) => {
                                                    if (a.posicao < b.posicao)
                                                        return -1;
                                                    if (a.posicao > b.posicao)
                                                        return 1;
                                                    return 0;
                                                }).map((aula) =>
                                                    <tr>
                                                        <td>{aula.posicao}</td>
                                                        <td>
                                                            <div className="avatar avatar-sm">
                                                                <img src={aula.miniatura ? aula.miniatura : curso.miniatura} alt="..." className="avatar-img rounded-circle" />
                                                            </div>

                                                        </td>
                                                        <td>{aula.titulo}</td>
                                                        <td>{aula.descricao.substr(0, 50)}...</td>
                                                        <td>
                                                            {aula.status === 'ATIVO' ?
                                                                <button style={{ width: 30, height: 30 }} type="button" class="btn btn-icon btn-success">
                                                                    <i style={{ fontSize: 13 }} class="fa fa-check "></i>
                                                                </button>
                                                                : aula.status === 'RASCUNHO' ?
                                                                    <button style={{ width: 30, height: 30 }} type="button" class="btn btn-icon btn-warning">
                                                                        <i style={{ fontSize: 13 }} class="fas fa-exclamation-circle"></i>
                                                                    </button>
                                                                    :
                                                                    <button style={{ width: 30, height: 30 }} type="button" class="btn btn-icon btn-danger">
                                                                        <i style={{ fontSize: 13 }} class="far fa-window-close"></i>
                                                                    </button>
                                                            }
                                                        </td>
                                                        <td>
                                                            <Link to={{ pathname: '/cadastraAula', state: { aula: aula, curso: curso, modulo: modulo } }} style={{ width: 30, height: 30 }} type="button" class="btn btn-icon btn-primary">
                                                                <i style={{ fontSize: 13 }} class="fa fa-search "></i>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                )}

                                            </tbody>
                                            {aulas.length === 0 ?
                                                <p>Sem aulas cadastradas</p>
                                                :
                                                null
                                            }
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}