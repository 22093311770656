import React, { useEffect, useState } from 'react';

// Componentes
// import Sidebar from '../../../Componentes/Sidebar'
// import Footer from '../../../Componentes/Footer';
// import Header from '../../../Componentes/Header';
import firebase from 'firebase';

export default function Perfil() {
    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection('usuarios')
    })
    const [usuario, setUsuario] = useState({})
    const [tab, setTab] = useState('basico')

    const [nome, setNome] = useState(usuario.nome)
    const [sobrenome, setSobrenome] = useState(usuario.sobrenome)
    const [dataNascimento, setDataNascimento] = useState(usuario.dataNascimento)


    useEffect(() => {
        // servicos.auth.onAuthStateChanged((u) => {
        //     if (u) {
        //         servicos.usuarios.doc(u.uid).get().then((user) => {
        //             setUsuario(user.data())
        //         })
        //     }
        // })
    }, [usuario])
    return (
        <>
            {/* <Sidebar /> */}
            <div className="all-content-wrapper">
                {/* <Header /> */}
                <div className="analytics-sparkle-area">
                    <div className="container-fluid">
                        <div className="breadcome-list" style={{ marginTop: 80 }}>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12">
                                    <h1>Meu Perfil</h1>
                                    <p>Edite seu perfil para melhorar suas informações com seus colegas</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


                <div className="library-book-area mg-t-30">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                <div className="single-cards-item">
                                    <div className="single-product-image">
                                        <a href="#"><img src="img/product/profile-bg.jpg" /></a>
                                    </div>
                                    <div className="single-product-text">
                                        <img src={usuario.avatar ? usuario.avatar : "img/product/pro4.jpg"} />
                                        <h4><a className="cards-hd-dn" href="#"></a></h4>
                                        <a className="follow-cards" href="#">Trocar Avatar</a>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-6 col-sm-6 col-xs-12">
                                <div className="single-pro-review-area mt-t-30 mg-b-15">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div className="product-payment-inner-st">
                                                    <ul id="myTabedu1" className="tab-review-design">
                                                        <li className={tab === 'basico' ? "active" : null}><a onClick={() => setTab('basico')} href="javascript:void(0)">Sobre Você</a></li>
                                                        <li className={tab === 'profissional' ? "active" : null}><a href="javascript:void(0)" onClick={() => setTab('profissional')}> Profissional</a></li>
                                                        <li className={tab === 'contato' ? "active" : null}><a href="javascript:void(0)" onClick={() => setTab('contato')}> Endereço e Contato</a></li>
                                                    </ul>
                                                    <div id="myTabContent" className="tab-content custom-product-edit">
                                                        <div className={tab === 'basico' ? "product-tab-list tab-pane fade active in" : "product-tab-list tab-pane fade in"} id="description">
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                    <div className="review-content-section">
                                                                        <div id="dropzone1" className="pro-ad">
                                                                            <form action="#" className="dropzone dropzone-custom needsclick add-professors" id="demo1-upload">
                                                                                <p><b>Campos pintados em vermelho são obrigatórios</b></p>
                                                                                <div className="row">
                                                                                    <div className="col-lg-5 col-md-6 col-sm-6 col-xs-12">
                                                                                        <div className="form-group">
                                                                                            <label>Nome</label>
                                                                                            <input style={{ borderColor: 'red' }} name="text" type="text" className="form-control" placeholder="Nome" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                                                                        <div className="form-group">
                                                                                            <label>Sobrenome</label>
                                                                                            <input style={{ borderColor: 'red' }} name="text" type="text" className="form-control" placeholder="Sobrenome" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                                                                        <div className="form-group">
                                                                                            <label>CPF</label>
                                                                                            <input style={{ borderColor: 'red' }} name="text" type="text" className="form-control" placeholder="Sobrenome" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                                                                        <div className="form-group">
                                                                                            <label>RG</label>
                                                                                            <input style={{ borderColor: 'red' }} name="text" type="text" className="form-control" placeholder="Sobrenome" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                                                                        <div className="form-group">
                                                                                            <label>Data Nascimento</label>
                                                                                            <input style={{ borderColor: 'red' }} name="text" type="date" className="form-control" placeholder="Sobrenome" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                                        <div className="form-group">
                                                                                            <label>Nome da Mãe</label>
                                                                                            <input name="text" type="text" className="form-control" placeholder="Nome" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                                        <div className="form-group">
                                                                                            <label>Nome do Pai</label>
                                                                                            <input name="text" type="text" className="form-control" placeholder="Sobrenome" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-12 col-md-6 col-sm-6 col-xs-12">
                                                                                        <div className="form-group">
                                                                                            <label>Sobre Você</label>
                                                                                            <textarea style={{ borderColor: 'red' }} name="description" placeholder='Descreva sobre você' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-12 col-md-6 col-sm-6 col-xs-12">
                                                                                        <div className="form-group">
                                                                                            <label>Seus Sonhos</label>
                                                                                            <textarea style={{ borderColor: 'red' }} name="description" placeholder='Descreva onde você quer chegar' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-12 col-md-6 col-sm-6 col-xs-12">
                                                                                        <button type="submit" className="btn btn-success waves-effect waves-light">Salvar Dados</button>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={tab === 'profissional' ? "product-tab-list tab-pane fade active in" : "product-tab-list tab-pane fade in"} id="reviews">
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                    <div className="review-content-section">
                                                                        <div id="dropzone1" className="pro-ad">
                                                                            <form action="#" className="dropzone dropzone-custom needsclick add-professors" id="demo1-upload">
                                                                                <p><b>Campos pintados em vermelho são obrigatórios</b></p>
                                                                                <div className="row">
                                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                                        <div className="form-group">
                                                                                            <label>Nome da sua escola</label>
                                                                                            <input style={{ borderColor: 'red' }} name="text" type="text" className="form-control" placeholder="Nome" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                                                                        <div className="form-group">
                                                                                            <label>Sua série</label>
                                                                                            <select style={{ borderColor: 'red' }} className="form-control">
                                                                                                <option>Escolha</option>
                                                                                                <option>1 ano ensino médio</option>
                                                                                                <option>2 ano ensino médio</option>
                                                                                                <option>Terceirão</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                                                                        <div className="form-group">
                                                                                            <label>Alunos na turma</label>
                                                                                            <select style={{ borderColor: 'red' }} className="form-control">
                                                                                                <option>Escolha</option>
                                                                                                <option>De 10 a 20</option>
                                                                                                <option>De 20 a 50</option>
                                                                                                <option>De 50 a 100</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                                                                        <div className="form-group">
                                                                                            <label>Matéria Preferida</label>
                                                                                            <select style={{ borderColor: 'red' }} className="form-control">
                                                                                                <option>Escolha</option>
                                                                                                <option value="matematica">Matemática</option>
                                                                                                <option value="portugues">Português</option>
                                                                                                <option value="historia">História</option>
                                                                                                <option value="geografia">Geografia</option>
                                                                                                <option value="fisica">Física</option>
                                                                                                <option value="quimica">Química</option>
                                                                                                <option value="biologia">Biologia</option>
                                                                                                <option value="filosofia">Filosofia</option>
                                                                                                <option value="sociologia">Sociologia</option>
                                                                                                <option value="ingles">Inglês</option>
                                                                                                <option value="espanhol">Espanhol</option>
                                                                                                <option value="artes">Artes</option>
                                                                                                <option value="educacaofisica">Educação Física</option>
                                                                                                <option value="ensinoreligioso">Ensino Religioso</option>
                                                                                                <option value="matematicafinanceira">Matemática Financeira</option>
                                                                                                <option value="literatura">Literatura</option>
                                                                                                <option value="tecnologiadainformacaoecomunicacao">Tecnologia da Informação e Comunicação</option>
                                                                                                <option value="orientacaoprofissional">Orientação Profissional</option>
                                                                                                <option value="ciencias">Ciências</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                                                                        <div className="form-group">
                                                                                            <label>Matéria com dificuldade</label>
                                                                                            <select style={{ borderColor: 'red' }} className="form-control">
                                                                                                <option>Escolha</option>
                                                                                                <option value="matematica">Matemática</option>
                                                                                                <option value="portugues">Português</option>
                                                                                                <option value="historia">História</option>
                                                                                                <option value="geografia">Geografia</option>
                                                                                                <option value="fisica">Física</option>
                                                                                                <option value="quimica">Química</option>
                                                                                                <option value="biologia">Biologia</option>
                                                                                                <option value="filosofia">Filosofia</option>
                                                                                                <option value="sociologia">Sociologia</option>
                                                                                                <option value="ingles">Inglês</option>
                                                                                                <option value="espanhol">Espanhol</option>
                                                                                                <option value="artes">Artes</option>
                                                                                                <option value="educacaofisica">Educação Física</option>
                                                                                                <option value="ensinoreligioso">Ensino Religioso</option>
                                                                                                <option value="matematicafinanceira">Matemática Financeira</option>
                                                                                                <option value="literatura">Literatura</option>
                                                                                                <option value="tecnologiadainformacaoecomunicacao">Tecnologia da Informação e Comunicação</option>
                                                                                                <option value="orientacaoprofissional">Orientação Profissional</option>
                                                                                                <option value="ciencias">Ciências</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="col-lg-12 col-md-6 col-sm-6 col-xs-12">
                                                                                        <div className="form-group">
                                                                                            <label>Descreva sobre sua escola</label>
                                                                                            <textarea style={{ borderColor: 'red' }} name="description" placeholder='Descreva sobre você' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={tab === 'contato' ? "product-tab-list tab-pane fade active in" : "product-tab-list tab-pane fade in"} id="INFORMATION">
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                    <div className="review-content-section">
                                                                        <div id="dropzone1" className="pro-ad">
                                                                            <form action="#" className="dropzone dropzone-custom needsclick add-professors" id="demo1-upload">
                                                                                <p><b>Campos pintados em vermelho são obrigatórios</b></p>

                                                                                <div className="row">
                                                                                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                                                                        <div className="form-group">
                                                                                            <label>Celular</label>
                                                                                            <input style={{ borderColor: 'red' }} name="text" type="text" className="form-control" placeholder="Nome" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                                                                        <div className="form-group">
                                                                                            <label>WhatsApp</label>
                                                                                            <input name="text" type="text" className="form-control" placeholder="Nome" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                                        <div className="form-group">
                                                                                            <label>Email</label>
                                                                                            <input style={{ borderColor: 'red' }} name="text" type="text" className="form-control" placeholder="Nome" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                                                                        <div className="form-group">
                                                                                            <label>CEP</label>
                                                                                            <input style={{ borderColor: 'red' }} name="text" type="text" className="form-control" placeholder="Nome" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                                                                        <div className="form-group">
                                                                                            <label>Endereço</label>
                                                                                            <input style={{ borderColor: 'red' }} name="text" type="text" className="form-control" placeholder="Nome" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-5 col-md-6 col-sm-6 col-xs-12">
                                                                                        <div className="form-group">
                                                                                            <label>Bairro</label>
                                                                                            <input style={{ borderColor: 'red' }} name="text" type="text" className="form-control" placeholder="Nome" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                                                                                        <div className="form-group">
                                                                                            <label>N</label>
                                                                                            <input style={{ borderColor: 'red' }} name="text" type="text" className="form-control" placeholder="Nome" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                                                                        <div className="form-group">
                                                                                            <label>Estado</label>
                                                                                            <input style={{ borderColor: 'red' }} name="text" type="text" className="form-control" placeholder="Nome" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-7 col-md-6 col-sm-6 col-xs-12">
                                                                                        <div className="form-group">
                                                                                            <label>Cidade</label>
                                                                                            <input style={{ borderColor: 'red' }} name="text" type="text" className="form-control" placeholder="Nome" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                                        <div className="form-group">
                                                                                            <label>Complemento</label>
                                                                                            <input style={{ borderColor: 'red' }} name="text" type="text" className="form-control" placeholder="Nome" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                                        <div className="form-group">
                                                                                            <label>Referencia</label>
                                                                                            <input style={{ borderColor: 'red' }} name="text" type="text" className="form-control" placeholder="Nome" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                {/* <Footer /> */}
            </div>

        </>
    );
}