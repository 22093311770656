import React, { useEffect, useState } from 'react';
import firebase from 'firebase';
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom';

// Componentes
import Sidebar from '../../Componentes/Sidebar'
import Header from '../../Componentes/Header';

export default function Alunos() {
    const [index, setIndex] = useState(0)
    const [alfabeto] = useState([
        { minimo: 'A', maximo: 'B' },
        { minimo: 'B', maximo: 'C' },
        { minimo: 'C', maximo: 'D' },
        { minimo: 'D', maximo: 'E' },
        { minimo: 'E', maximo: 'F' },
        { minimo: 'F', maximo: 'G' },
        { minimo: 'G', maximo: 'H' },
        { minimo: 'H', maximo: 'I' },
        { minimo: 'I', maximo: 'J' },
        { minimo: 'J', maximo: 'K' },
        { minimo: 'K', maximo: 'L' },
        { minimo: 'L', maximo: 'M' },
        { minimo: 'M', maximo: 'N' },
        { minimo: 'N', maximo: 'O' },
        { minimo: 'O', maximo: 'P' },
        { minimo: 'P', maximo: 'Q' },
        { minimo: 'Q', maximo: 'R' },
        { minimo: 'R', maximo: 'S' },
        { minimo: 'S', maximo: 'T' },
        { minimo: 'T', maximo: 'U' },
        { minimo: 'U', maximo: 'V' },
        { minimo: 'V', maximo: 'W' },
        { minimo: 'W', maximo: 'X' },
        { minimo: 'X', maximo: 'Y' },
        { minimo: 'Y', maximo: 'Z' },
        { minimo: 'Z', maximo: '' },
    ])
    const [servicos] = useState({
        auth: firebase.auth(),
        alunos: firebase.firestore().collection('usuarios'),
    })
    const [alunos, setAlunos] = useState([])
    const [filtro, setFiltro] = useState('A')

    useEffect(() => {
        servicos.alunos
            .where('usuario', '==', 'ALUNO')
            .where('nome', '>=', 'A')
            .where('nome', '<', 'B')
            .get().then((snap) => {
                const data = snap.docs.map((d) => d.data())
                setAlunos(data)
            })
    }, [])

    function pagination(min, max, ind) {
        setFiltro(min)
        setIndex(ind)
        setAlunos([])
        mensagemLoading('Carregando alunos. Isso pode demorar alguns minutos ...')
        if (!max) {
            servicos.alunos
                .where('usuario', '==', 'ALUNO')
                .where('nome', '>=', min)
                .get().then((d) => {
                    const list = []
                    const data = d.docs.map(doc => doc.data())
                    setAlunos(data)
                    Swal.close()
                })
            return
        } else {
            servicos.alunos
                .where('usuario', '==', 'ALUNO')
                .where('nome', '>=', min)
                .where('nome', '<', max)
                .get().then((d) => {
                    const list = []
                    const data = d.docs.map(doc => doc.data())
                    setAlunos(data)
                    Swal.close()
                })
        }

    }

    // Inputs
    const handleFiltro = (event) => {
        event.persist();
        setFiltro(event.target.value);
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="dashboard-main-wrapper">
            <Header />
            <Sidebar />
            <div className="main-panel">
                <div className="content">
                    <div className="page-inner">
                        <div className="page-header">
                            <h4 className="page-title">Alunos</h4>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div class="card-header">
                                            <div class="card-title">Lista de Alunos do Fábrica</div>
                                        </div>
                                        <div className="row" style={{marginTop:10}}>
                                            <div style={{ marginLeft: 10 }}>
                                                {alfabeto.map((a, ind) =>
                                                    <button style={{ width: 30, height: 30,marginLeft:2 }} onClick={() => pagination(a.minimo, a.maximo, ind)} key={ind} type="button" class={filtro === a.minimo ? "btn btn-success" : "btn  btn-default"}>{a.minimo}</button>
                                                )}
                                            </div>
                                        </div>
                                        <div className="card-sub" style={{marginTop:10}}>
                                            Aqui estão listadas todos os alunos que fazem parte do Fábrica
                                        </div>
                                        <table className="table mt-3">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Avatar</th>
                                                    <th scope="col">Nome</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Escola</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Detalhe</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {alunos.map((aluno) =>
                                                    <tr key={aluno.id}>
                                                        <td>
                                                            <div className="avatar avatar-sm">
                                                                <img src={aluno.avatar} alt="..." className="avatar-img rounded-circle" />
                                                            </div>
                                                        </td>
                                                        <td>{aluno.nome}</td>
                                                        <td>{aluno.email}</td>
                                                        <td>{aluno.escola.nome}</td>
                                                        <td>
                                                            {aluno.status === 'ATIVO' ?
                                                                <button style={{ width: 30, height: 30 }} type="button" class="btn btn-icon btn-success">
                                                                    <i style={{ fontSize: 13 }} class="fa fa-check "></i>
                                                                </button>
                                                                : aluno.status === 'RASCUNHO' ?
                                                                    <button style={{ width: 30, height: 30 }} type="button" class="btn btn-icon btn-warning">
                                                                        <i style={{ fontSize: 13 }} class="fas fa-exclamation-circle"></i>
                                                                    </button>
                                                                    :
                                                                    <button style={{ width: 30, height: 30 }} type="button" class="btn btn-icon btn-danger">
                                                                        <i style={{ fontSize: 13 }} class="far fa-window-close"></i>
                                                                    </button>
                                                            }
                                                        </td>
                                                        <td>
                                                            <Link to={{ pathname: '/cadastroCurso', state: { aluno: aluno } }} style={{ width: 30, height: 30 }} type="button" class="btn btn-icon btn-primary">
                                                                <i style={{ fontSize: 13 }} class="fa fa-search "></i>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                            {alunos.length === 0 ?
                                            <p>Sem professores para a inicial {filtro}</p>
                                            :
                                            null
                                            }
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}