import React, { useEffect, useState } from 'react';

// Componentes
// import Sidebar from '../Componentes/Sidebar'
// import Footer from '../Componentes/Footer';
// import Header from '../Componentes/Header';
import firebase from 'firebase';

export default function MeusCursos() {
    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection('usuarios')
    })
    const [usuario, setUsuario] = useState({})
    const [cursos, setCursos] = useState([])
    useEffect(() => {
        servicos.auth.onAuthStateChanged((u) => {
            if (u) {
                servicos.usuarios.doc(u.uid).get().then((user) => {
                    setUsuario(user.data())
                    servicos.usuarios.doc(user.data().id).collection('cursos').get().then((d) => {
                        const data = d.docs.map((d) => d.data())
                        setCursos(data)
                    })
                })
            }
        })
    }, [usuario])
    return (
        <>
            {/* <Sidebar /> */}
            <div className="all-content-wrapper">
                {/* <Header /> */}
                <div className="analytics-sparkle-area">
                    <div className="container-fluid">
                        <div className="breadcome-list" style={{ marginTop: 80 }}>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12">
                                    <h1>Meus Cursos</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="traffic-analysis-area">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <div className="social-media-edu">
                                    <i className="fa fa-facebook" />
                                    <div className="social-edu-ctn">
                                        <h3></h3>
                                        <p>You main list growing</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <div className="social-media-edu twitter-cl res-mg-t-30 table-mg-t-pro-n">
                                    <i className="fa fa-twitter" />
                                    <div className="social-edu-ctn">
                                        <h3>30k followers</h3>
                                        <p>You main list growing</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <div className="social-media-edu linkedin-cl res-mg-t-30 res-tablet-mg-t-30 dk-res-t-pro-30">
                                    <i className="fa fa-linkedin" />
                                    <div className="social-edu-ctn">
                                        <h3>7k Connections</h3>
                                        <p>You main list growing</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <div className="social-media-edu youtube-cl res-mg-t-30 res-tablet-mg-t-30 dk-res-t-pro-30">
                                    <i className="fa fa-youtube" />
                                    <div className="social-edu-ctn">
                                        <h3>50k Subscribers</h3>
                                        <p>You main list growing</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="courses-area" style={{marginBottom:20}}>
                    <div className="container-fluid">
                        <div className="row">
                            {cursos.map((curso) =>
                                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                    <div className="courses-inner res-mg-b-30">
                                        <div className="courses-title">
                                            <a href="#"><img src={curso.miniatura} alt /></a>
                                            <h2>{curso.titulo}</h2>
                                        </div>
                                        <div className="courses-alaltic">
                                            <p>{curso.descricao}</p>
                                        </div>
                                        <div className="course-des">
                                            <p><span><i className="fa fa-clock" /></span> <b>Duração:</b> {curso.horas} horas</p>
                                        </div>
                                        <div className="product-buttons">
                                            <button type="button" className="button-default cart-btn">Ver curso</button>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>


                {/* <Footer /> */}
            </div>

        </>
    );
}