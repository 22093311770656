import React, { useState } from 'react';
import firebase from 'firebase'
import Swal from 'sweetalert2'

export default function Login() {
    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection('usuarios')
    })
    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')

    function realizaLogin() {
        if (!email) {
            mensagemAlerta('Por favor digite seu Email')
            return
        }
        if (!senha) {
            mensagemAlerta('Por favor digite sua senha')
            return
        }
        mensagemLoading('Realizando login...')
        servicos.auth.signInWithEmailAndPassword(email, senha).then((res) => {
            servicos.usuarios.doc(res.user.uid).get().then((snap) => {
                if (snap.data().usuario === 'ADMINISTRADOR') {
                    window.location = '/administrador';
                } else if (snap.data().usuario === 'PROFESSOR') {
                    window.location = '/professor';
                } else if (snap.data().usuario === 'ALUNO') {
                    window.location = '/aluno';
                } else {
                    Swal.close();
                    mensagemAlerta('Usuário não autorizado para este ambiente.')
                    firebase.auth().signOut();
                }
            })
        }).catch((error) => {
            console.log(error)
            var errorCode = error.code;
            if (error) {
                if (errorCode === 'auth/invalid-email') {
                    Swal.close()
                    mensagemAlerta('Email incompleto ou não é válido')
                } else if (errorCode === 'auth/user-not-found') {
                    Swal.close()
                    mensagemAlerta('Usuário não cadastrado! Cadastre-se ou verifique novamente')
                } else if (errorCode === 'auth/wrong-password') {
                    Swal.close()
                    mensagemAlerta('Senha Incorreta')
                }
            }
        });
    }

    // Inputs
    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }
    const handleSenha = (event) => {
        event.persist();
        setSenha(event.target.value);
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    function mostraSenha() {
        let temp = document.getElementById("typepass");

        if (temp.type === "password") {
            temp.type = "text";
        }
        else {
            temp.type = "password";
        }
    }

    return (
        <div className='login'>
            <div className="wrapper wrapper-login">
                <div className="container container-login animated fadeIn">
                    <div style={{textAlign: 'center' }}>
                        <img style={{ width: 100, height: 100, alignContent: 'center' }} src='assets/img/logo.jpeg' />
                    </div>
                    <h3 className="text-center" style={{marginTop:10}}>Faça login no administrador</h3>
                    <div className="login-form">
                        <div className="form-group">
                            <label htmlFor="username" className="placeholder"><b>Email</b></label>
                            {/* <InputMask mask="999.999.999-99" placeholder='' onChange={handleEmail} value={email} name="username" type="text" className="form-control" /> */}
                            <input id="username" onChange={handleEmail} value={email} name="username" type="text" className="form-control" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password" className="placeholder"><b>Senha</b></label>
                            <a href="#" className="link float-right">Esqueceu a senha ?</a>
                            <div className="position-relative">
                                <input id="typepass" onChange={handleSenha} value={senha} name="password" type="password" className="form-control" required />
                                <div className="show-password">
                                    <i onClick={() => mostraSenha()} className="flaticon-interface" />
                                </div>
                            </div>
                        </div>
                        <div className="form-group form-action-d-flex mb-3">
                            <button onClick={() => realizaLogin()} className="btn btn-primary col-md-12 float-right mt-3 mt-sm-0 fw-bold">Login</button>
                        </div>
                        {/* <div className="login-account">
                                <span className="msg">Don't have an account yet ?</span>
                                <a href="#" id="show-signup" className="link">Sign Up</a>
                            </div> */}
                    </div>
                </div>

            </div>
        </div>
    );
}