import React, { Component, Profiler } from 'react';
import { BrowserRouter, Route } from 'react-router-dom'

// Autenticação
import Login from './pages/Autenticacao/Login'
import Administrador from './pages/Autenticacao/Perfil/Administrador'
import Professor from './pages/Autenticacao/Perfil/Professor'
import Aluno from './pages/Autenticacao/Perfil/Aluno'
import Perfil from './pages/Autenticacao/Perfil/Perfil'

//Admin
// ==> Blog
import CategoriasDeBlog from './pages/Blog/Categorias';
import Blog from './pages/Blog/Lista';
import CadastroPost from './pages/Blog/Cadastro';

//Admin
// ==> Projetos
import Projetos from './pages/Projetos/Lista';
import CadastroProjeto from './pages/Projetos/Cadastro';

// ==> Cursos
import Categorias from './pages/Cursos/Categorias';
import Cursos from './pages/Cursos/Lista';
import CadastroCurso from './pages/Cursos/Cadastro';
import Aulas from './pages/Cursos/Aulas';
import CadastraAula from './pages/Cursos/CadastraAula';

//  ==> Alunos
import Alunos from './pages/Alunos/Lista';

//  ==> Professores
import Professores from './pages/Professores';

// ALUNO
import MeusCursos from './pages/MeusCursos';

//  ==> Financeiro
import Financeiro from './pages/Financeiro';


export default class Routes extends Component {
    render() {
        return (
            <div >
                <BrowserRouter>
                    {/* Autenticação */}
                    <Route path="/" exact={true} component={Login} />
                    <Route path="/administrador" exact={true} component={Administrador} />
                    <Route path="/professor" exact={true} component={Professor} />
                    <Route path="/aluno" exact={true} component={Aluno} />
                    <Route path="/perfil" exact={true} component={Perfil} />

                    {/* Admin */}
                    {/* ==> Blog */}
                    <Route path="/CategoriasBlog" exact={true} component={CategoriasDeBlog} />
                    <Route path="/blog" exact={true} component={Blog} />
                    <Route path="/cadastroPost" exact={true} component={CadastroPost} />

                    {/* ==> Blog */}
                    <Route path="/projetos" exact={true} component={Projetos} />
                    <Route path="/cadastroProjeto" exact={true} component={CadastroProjeto} />
                    
                    {/* ==> Cursos */}
                    <Route path="/categoriasCursos" exact={true} component={Categorias} />
                    <Route path="/cursos" exact={true} component={Cursos} />
                    <Route path="/cadastroCurso" exact={true} component={CadastroCurso} />
                    <Route path="/aulas" exact={true} component={Aulas} />
                    <Route path="/cadastraAula" exact={true} component={CadastraAula} />

                    {/* ==> Alunos */}
                    <Route path="/alunos" exact={true} component={Alunos} />

                    {/* ==> Professores */}
                    <Route path="/professores" exact={true} component={Professores} />

                    {/* Aluno */}
                    {/* ===> Meus Cursos */}
                    <Route path="/meusCursos" exact={true} component={MeusCursos} />

                    {/* ==> Financeiro */}
                    <Route path="/financeiro" exact={true} component={Financeiro} />
                </BrowserRouter>
            </div>
        );
    }
}