import React, { useEffect, useState } from 'react';
import firebase from 'firebase';
import Swal from 'sweetalert2'

// Componentes
import Sidebar from '../../Componentes/Sidebar'
import Header from '../../Componentes/Header';
import moment from 'moment';

export default function CadsatroPost() {
    const [servicos] = useState({
        auth: firebase.auth(),
        blog: firebase.firestore().collection('blog'),
        usuario: firebase.firestore().collection('usuarios'),
        categoriasBlog: firebase.firestore().collection('categoriasBlog').where('status', '==', 'ATIVO'),
    })
    const [usuario, setUsuario] = useState({})
    const [categorias, setCategorias] = useState([])
    const [blog, setBlog] = useState({})

    // Post
    const [titulo, setTitulo] = useState('')
    const [descricao, setDescricao] = useState('')
    const [categoria, setCategoria] = useState({})
    const [conteudo, setConteudo] = useState('')
    const [publicacao, setPublicacao] = useState('')
    const [status, setStatus] = useState('')

    var metadata = { contentType: 'image/jpeg' };
    const [miniatura, setMiniatura] = useState('');
    const [uploadMiniatura, setUploadMiniatura] = useState('');
    const [imagem, setImagem] = useState('');
    const [uploadImagem, setUploadImagem] = useState('');

    useEffect(() => {

        if (window.location.search.replace('?', '')) {
            servicos.blog.doc(window.location.search.replace('?', '')).get().then((b) => {
                setBlog(b.data())
                setMiniatura(b.data().miniatura)
                setImagem(b.data().imagem)
                setTitulo(b.data().titulo)
                setDescricao(b.data().descricao)
                setCategoria(b.data.categoria)
                setConteudo(b.data().conteudo)
                setPublicacao(b.data().publicacao)
                setStatus(b.data().status)
            })
        }
        servicos.auth.onAuthStateChanged((user) => {
            if (user) {
                servicos.usuario.doc(user.uid).get().then((u) => {
                    setUsuario(u.data())
                })
            }
        })
        servicos.categoriasBlog.get().then((snap) => {
            const data = snap.docs.map((d) => d.data())
            setCategorias(data)
        })

    }, [])

    function cadastrar() {
        console.log(categoria)
        if (!miniatura) {
            mensagemAlerta('Escolha a imagem da miniatura')
            return
        }
        if (!imagem) {
            mensagemAlerta('Escolha a imagem Principal')
            return
        }
        if (!titulo) {
            mensagemAlerta('Digite o Titulo')
            return
        }
        if (!descricao) {
            mensagemAlerta('Digite a Descrição')
            return
        }
        
        if (!conteudo) {
            mensagemAlerta('Digite o conteúdo')
            return
        }
        if (!publicacao) {
            mensagemAlerta('Escolha a publicação')
            return
        }
        if (!status) {
            mensagemAlerta('Escolha o Status')
            return
        }
        if(blog.id){
            mensagemLoading('Atualizando Post..')
            console.log(categoria)
            servicos.blog.doc(blog.id).update({
                titulo: titulo,
                descricao: descricao,
                categoria: {
                    categoria: categoria && categoria.id? categoria.nome: blog.categoria.categoria,
                    id: categoria && categoria.id? categoria.id: blog.categoria.id
                },
                conteudo: conteudo,
                publicacao: publicacao,
                status: status,
                usuario: {
                    nome: usuario.nome,
                    id: usuario.id,
                    email: usuario.email
                },
                dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
            }).then((snap) => {
                if(uploadMiniatura){
                    cadastraMiniatua(blog.id)
                }else if(uploadImagem){
                    cadastraImagem(blog.id)
                }else{
                    mensagemSucesso('Post atualizado com sucesso!')
                }
            })
        }else{
            mensagemLoading('Cadastrando Post..')
            servicos.blog.add({
                titulo: titulo,
                descricao: descricao,
                categoria: {
                    categoria: categoria.nome,
                    id: categoria.id
                },
                conteudo: conteudo,
                publicacao: publicacao,
                status: status,
                usuario: {
                    nome: usuario.nome,
                    id: usuario.id,
                    email: usuario.email
                },
                dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
            }).then((snap) => {
                servicos.blog.doc(snap.id).update({
                    id: snap.id
                }).then(() => {
                    cadastraMiniatua(snap.id)
                })
            })
        }
        

    }

    function cadastraMiniatua(id) {
        Swal.close();
        mensagemLoading('Enviando a miniatura...')
        var uploadTask = firebase.storage().ref().child('blog/' + id + '/miniatura.' + miniatura[0].type.replace('image/', '')).put(miniatura[0], { contentType: miniatura[0].type });
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.blog.doc(id).update({
                    miniatura: downloadURL
                }).then(() => {
                    if (uploadImagem) {
                        cadastraImagem(id)
                    } else {
                        mensagemSucesso('Miniatura atualizada com sucesso')
                    }

                })
            });
        });
    }

    function cadastraImagem(id) {
        Swal.close();
        mensagemLoading('Enviando a imagem principal...')
        var uploadTask = firebase.storage().ref().child('blog/' + id + '/imagem.' + imagem[0].type.replace('image/', '')).put(imagem[0], { contentType: imagem[0].type });
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.blog.doc(id).update({
                    imagem: downloadURL
                }).then(() => {
                    mensagemSucesso('Post cadastrado com sucesso')
                })
            });
        });
    }

    // Inputs
    const handleMiniatura = (event) => {
        event.persist();
        setMiniatura(event.target.files)
        console.log(event.target.files[0].type.replace('image/', ''))
        setUploadMiniatura(URL.createObjectURL(event.target.files[0]))
    }
    const handleImagem = (event) => {
        event.persist();
        setImagem(event.target.files)
        setUploadImagem(URL.createObjectURL(event.target.files[0]))
    }
    const handleTitulo = (event) => {
        event.persist();
        setTitulo(event.target.value);
    }
    const handleDescricao = (event) => {
        event.persist();
        setDescricao(event.target.value);
    }
    const handleCategoria = (event) => {
        event.persist();
        if (event.target.value) {
            setCategoria(categorias[event.target.value])
        }
    }
    const handleConteudo = (event) => {
        event.persist();
        setConteudo(event.target.value);
    }
    const handlePublicacao = (event) => {
        event.persist();
        setPublicacao(event.target.value);
    }
    const handleStatus = (event) => {
        event.persist();
        setStatus(event.target.value);
    }

    // Mensagens
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.history.back()
        })
    }
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <>
            <Sidebar />
            <div className="all-content-wrapper">
                <Header />
                <div className="analytics-sparkle-area">
                    <div className="container-fluid">
                        <div className="breadcome-list" style={{ marginTop: 80 }}>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12">
                                    <h1>Novo Post</h1>
                                </div>
                            </div>
                        </div>
                        <div className="single-pro-review-area mt-t-30 mg-b-15">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="product-payment-inner-st">
                                            <div>
                                                <h5>Imagens do Post</h5>
                                            </div>
                                            <div className="row" style={{ marginTop: 20 }}>
                                                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                                    <div class="profile-img">
                                                        {uploadMiniatura ?
                                                            <img src={uploadMiniatura} alt="" />
                                                            : miniatura ?
                                                                <img src={miniatura} alt="" />
                                                                :
                                                                <img src="https://cdn-icons-png.flaticon.com/512/3342/3342176.png" alt="" />
                                                        }

                                                        <p style={{ marginTop: 20 }}>Imagem de miniatura</p>
                                                        <input accept='.png,.jpg,.jpeg' onChange={handleMiniatura} type="file" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-8 col-md-6 col-sm-6 col-xs-12">
                                                    <div class="profile-img">
                                                        {uploadImagem ?
                                                            <img src={uploadImagem} alt="" />
                                                            : imagem ?
                                                                <img src={imagem} alt="" />
                                                                :
                                                                <img src="https://i.pinimg.com/736x/ec/14/7c/ec147c4c53abfe86df2bc7e70c0181ff.jpg" alt="" />
                                                        }

                                                        <p style={{ marginTop: 20 }}>Imagem Principal</p>
                                                        <input accept='.png,.jpg,.jpeg' onChange={handleImagem} type="file" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="single-pro-review-area mt-t-30 mg-b-15">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="product-payment-inner-st">
                                            <div>
                                                <h5>Dados do Post</h5>
                                            </div>
                                            <div className="row" style={{ marginTop: 20 }}>

                                                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                        <input value={titulo} onChange={handleTitulo} name="firstname" type="text" className="form-control" placeholder="Título" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                        <input value={descricao} onChange={handleDescricao} name="firstname" type="text" className="form-control" placeholder="Descrição" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                        <select name="gender" className="form-control" onChange={handleCategoria}>
                                                            <option value="">Categoria</option>
                                                            {categorias.map((categoria, index) =>
                                                                <option value={index} selected={blog.id && blog.categoria.id === categoria.id ? 'selected' : null}>{categoria.nome}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-6 col-sm-6 col-xs-12">
                                                    <div class="form-group res-mg-t-15">
                                                        <textarea value={conteudo} onChange={handleConteudo} placeholder="Descreva o Post"></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                        <select name="gender" className="form-control" onChange={handlePublicacao}>
                                                            <option value="">Publicação</option>
                                                            <option value="EXTERNO" selected={blog.id && blog.publicacao === 'EXTERNO' ? 'selected' : null}>Externo</option>
                                                            <option value="PROPRIO" selected={blog.id && blog.publicacao === 'PROPIO' ? 'selected' : null}>Própria</option>
                                                            <option value="COLABORADOR" selected={blog.id && blog.publicacao === 'COLABORADOR' ? 'selected' : null}>Colaborador</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group">
                                                        <select name="gender" className="form-control" onChange={handleStatus}>
                                                            <option value="">Status</option>
                                                            <option value="ATIVO" selected={blog.id && blog.status === 'ATIVO' ? 'selected' : null}>Ativo</option>
                                                            <option value="INATIVO" selected={blog.id && blog.status === 'INATIVO' ? 'selected' : null}>Inativo</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                                    {blog && blog.id ?
                                                        <button onClick={() => cadastrar()} style={{ height: 40 }} type="submit" class="btn btn-primary waves-effect waves-light">Atualizar</button>
                                                        :
                                                        <button onClick={() => cadastrar()} style={{ height: 40 }} type="submit" class="btn btn-primary waves-effect waves-light">Cadastrar</button>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}